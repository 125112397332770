@import 'src/Styles/Mixin/index.scss';
@import 'src/Styles/Variable/index.scss';

.overviewBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
  margin: 0px 30px;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #f1f1f4;
}

.overviewBoxTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $fs-20;
  font-family: $SfPro-semibold;
  margin-bottom: 30px;
  font-weight: $fw-semi-medium;
}

.overviewCardCount {
  font-size: $fs-36;
  font-weight: $fw-medium;
  font-family: $SfUi-medium;
}
.overviewCardTxt {
  font-size: $fs-18;
  font-weight: $fw-medium;
  font-family: $SfUi-medium;
  color: #99a1b7;
  text-wrap: pretty;
  padding: 2px 0px;
  list-style: normal;
}

.overviewCard {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.overviewCardContent {
  display: flex;
  width: 70%;
  flex-direction: column;
  img {
    filter: sepia(10) hue-rotate(200deg) saturate(100);
  }
}

.overviewGridBox {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 30px;
  width: 23.5%;
  border-radius: 20px;
  background-color: $lightMain;
}
