@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.AppPageBox {
     align-items: flex-start;
     background-color: $white;
     border: 1px solid transparent;
     border-radius: 20px;
     display: flex;
     flex-direction: column;
}

.AppHeaderPaper {
    align-items: center;
       background-color: $white;
       align-self: stretch;
       border-color: #e1e4eb;
       display: flex;
       gap: 10px;
       height: 70px;
       border-radius: 15px;
       padding: 15px 30px;
       position: relative;
       width: 100%;
}

.TitleFont {
      color: $black;
     font-size: $fs-20;
     font-weight: $fw-bold;
     letter-spacing: 0;
     line-height: 24px;
     position: relative;
     white-space: nowrap;
}

.GridStyle {
    background: $white;
     border-radius: 15px;
}
.verticalDevider {
    border-right: 1px solid #e1e4eb;
}

.ContentGridStyle {
    border-top: 1px solid #e1e4eb;
    overflow: auto; 
    max-height: 70vh; 
}

.GridScroll{
    max-height: 135vh;
    overflow-y: auto;
}
