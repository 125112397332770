@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.Main {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  position: relative;
  padding: 30px;
}

.Head {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
  position: relative;
  width: 100%;
}

.HeadText {
  color: #190482;
  font-family: $SfPro-semibold;
  font-size: $fs-24;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.SubText {
  color: #99a1b7;
  font-family: $SfPro-regular;
  font-weight: $fw-medium;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-align: center;
}

.TabText {
  color: #99a1b7;
  font-size: $fs-16;
  font-family: $SfPro-semibold;
  // font-weight: $fw-bold;
  line-height: 20px;
}

.Right {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  height: 40px;
  position: relative;
}

.Left {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  position: relative;
}

.SearchBox {
  align-items: center;
  background-color: $white;
  border: 1px solid;
  border-color: $dark-gray;
  border-radius: 10px;
  display: flex;
  gap: 6px;
  padding: 8px 15px;
  position: relative;
  width: 300px;
}

.Action {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.SearchEm {
  background: transparent;
  border: none;
  border-bottom: none;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  width: -webkit-fill-available;
  color: #c6cbda;
}

.SearchEm > input {
  padding: 0;
}

.Add {
  align-items: center;
  width: fit-content;
  background-color: $themeMain;
  border-radius: 10px;
  display: inline-flex;
  padding: 8px 20px;
  margin: 0;
  height: auto;
}

.ListWrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  position: relative;
  width: 100%;
}

.ListGrid {
  align-items: flex-start;
  display: flex;
  flex: 1;
  gap: 20px 20px;
  position: relative;
  border-radius: 15px;
  flex-grow: 1;
  flex-wrap: wrap;
}

.Table {
  width: 100%;
  background-color: $white;
  color: $blue;
  border-radius: $sp-3;
  padding: $sp-4;
}
