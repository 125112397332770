@import '/src/Styles/Variable/index.scss';
@import '/src/Styles/Mixin/index.scss';

.containerHeight {
  height: calc(100vh);
  position: fixed;
}
@media (max-width: 1100px) {
  .containerHeight {
    position: absolute;
  }
}
.secondContainerHeight {
  height: calc(100vh - 20px);
}

.wBox {
  width: 460px;
}
.bgCmn1 {
   background-color: $white;
}
.bgCmn4 {
  background-color: $gray;
}

.bgCmn7 {
  background-color: $white-variant-1;
}
.br10 {
  border-radius: 10px ;
}
.br20 {
  border-radius: 10px ;
}
.p10 {
  padding: 10px ;
}

.center {
justify-content: center;
align-items: center;
display: flex;
}
.btnSubmit {
  background-color: $themeMain;
  color: $white;
}

.textFieldWrapper {
  display: flex;
  color: $black;

  .FF002E {
    color: $red;
  }
}

.flexStartBox {
  @include flex(column, flex-start, center);
  @include border-radius();
  align-self: stretch;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.field {
  @extend %field
}
