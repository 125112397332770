@import '/src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

// *::-webkit-scrollbar {
//   display: none;
// }

/* width */
*::-webkit-scrollbar {
  width: 10px;
  margin: 20px 0;
}

/* Track */
*::-webkit-scrollbar-track {
  background: transparent;
  margin: 20px 0;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.mainSidebarBox {
  position: sticky;
  width: 320px;
  border-right: 1.5px solid #dbdfe9;
  min-height: calc(100vh - 92px);
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
// .mainSidebarBox::-webkit-scrollbar {
//   display: none;
// }
.SubSidebarBox {
  padding: 30px;
  height: 100px;
  border-radius: 1.5;
  display: flex;
  align-items: center;
}

.AvtarBox {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.avatarImage {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  padding: 1px;
  img {
    width: 82%;
    height: 82%;
    border-radius: 50%;
  }
}

.avatarProgress {
  color: $green;
  border-radius: 2.5px;
  position: absolute;
  rotate: 135deg;
  top: 0;
  left: 0;
}

.sidebarBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 192px);
  // gap: 200px;
}

.listBox {
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  position: relative;
  right: 10px;
}

.userName {
  font-family: $SfUi-medium;
  font-size: $fs-22;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.userRole {
  font-family: $SfPro-regular;
  font-size: $fs-18;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.stepBox {
  position: absolute;
  left: 10px;
}
.subActive{
  margin-left: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  text-transform: capitalize;
  font-family: $SfPro-regular;
  font-size: $fs-18;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: $themeMain;
  img {
    filter: sepia(10) hue-rotate(200deg) saturate(100);
  }
}
.sublistItemBtn{
  margin-left: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  text-transform: capitalize;
  font-family: $SfPro-regular;
  font-size: $fs-18;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: $darker-gray;
}
.listItemBtn {
  text-transform: capitalize;
  font-family: $SfPro-regular;
  font-size: $fs-18;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: $darker-gray;
}
.Active {
  text-transform: capitalize;
  font-family: $SfPro-regular;
  font-size: $fs-18;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: $themeMain;
  img {
    filter: sepia(10) hue-rotate(200deg) saturate(100);
  }
}

.subContainer {
  margin: 30px;
  padding: 30px;
  width: -webkit-fill-available;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  background-color: #f8f6ff;
  align-items: center;
}

.subContainerText {
  font-family: $SfUi-semibold;
  font-size: $fs-18;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: $black;
}

.subContainerGrid {
  width: 100%;
}

.subContainerButton {
  font-family: $SfUi-medium;
  font-size: $fs-16;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  white-space: nowrap;
  width: 100%;
  background-color: $themeMain;
}

.css-i4bv87-MuiSvgIcon-root {
  margin-left: 5px;
}

.css-tlelie-MuiListItemText-root {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  display: contents;
}
.css-1pqm26d-MuiAvatar-img {
  width: 82%;
  height: 82%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
  border-radius: 50%;
}
.css-i4bv87-MuiSvgIcon-root {
  margin-left: 5px;
}

.css-tlelie-MuiListItemText-root {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  display: contents;
}
.css-1pqm26d-MuiAvatar-img {
  width: 82%;
  height: 82%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
  border-radius: 50%;
}
