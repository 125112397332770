@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.samanthaLynnDavis {
  width: 433px;
  position: relative;
  line-height: 30px;
  display: flex;
  align-items: center;
  max-width: 104%;
  flex-shrink: 0;
}
.ssnText {
  width: 433px;
  position: relative;
  line-height: 30px;
  display: flex;
  align-items: center;
  max-width: 104%;
  font-size: $fs-16;
  color: $darker-gray;
  flex-shrink: 0;
}
.samanthaLynnDavisWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  // min-width: 190px;
  // max-width: 100%;
}
.iconmore {
  height: 24px;
  width: 24px;
  position: relative;
}
.frameGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  max-width: 100%;
  cursor: pointer;
}
.ssn0 {
  align-self: stretch;
  position: relative;
  font-size: $fs-16;
  line-height: 20px;
  color: $darker-gray;
  align-content: center;
}
.frameParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  cursor: pointer;
}
.div,
.wrapper {
  box-sizing: border-box;
  max-width: 100%;
}
.div {
  height: 1px;
  flex: 1;
  position: relative;
  border-top: 1px dashed $dark-gray;
}
.wrapper {
  align-self: stretch;
  height: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px 0 0;
}

.iconlocation {
  height: 16px;
  width: 16px;
  position: relative;
}
.aAvenueJf {
  position: relative;
  line-height: 20px;
}
.location {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0 6px;
}
.phoneIcon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.div1 {
  position: relative;
  line-height: 20px;
}
.phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 6px;
}
.mail01Icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.ellyseperrygmailcom {
  position: relative;
  line-height: 20px;
  white-space: nowrap;
}
.email {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 6px;
}
.contact,
.info {
  display: flex;
  align-items: flex-start;
}
.contact {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0 30px;
}
.info {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: 8px 0;
  max-width: 100%;
}
.infoWrapper,
.progress {
  align-self: stretch;
  max-width: 100%;
}
.infoWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px 0 0;
  box-sizing: border-box;
  font-weight: $fw-medium;
  color:$darker-gray;
}
.progress {
  flex: 1;
}
.progressWrapper {
  align-self: stretch;
  height: 36px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 7px 0 0;
  margin-top: 16px;
}
.list,
.progressWrapper,
.separatorFrame {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.separatorFrame {
  flex: 1;
  border-radius: 20px;
  background-color: $white;
  flex-direction: column;
  align-items: center;
  padding: 30px 23px 30px 30px;
  gap: 20px 0;
  // min-width: 335px;
  font-size: $fs-18;
  color: $themeMain;
}
.list {
  width: 1580px;
  border-radius: 15px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0 28px 0 20px;
  gap: 30px 26.5px;
  text-align: left;
  font-weight: $fw-medium;
  color: $darker-gray;
}
@media screen and (max-width: 450px) {
  .contact,
  .frameGroup {
    flex-wrap: wrap;
    cursor: pointer;
  }
  .contact {
    justify-content: center;
  }
}
