@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.MainBox {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  margin: 30px;
}

.title {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 100%;
}

.main {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.textWrapper {
  color: $black;
  font-family: $SfPro-semibold;
  font-size: $fs-20;
  font-weight: $fw-semi-medium;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
}

.button {
  all: unset;
  align-items: flex-start;
  background-color: $gray;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 6px 15px;
  position: relative;
  background-color: #F9F9F9;
}

.div {
color: $darker-gray;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
  padding: 4px 10px;
  border-radius: 8px;
}

.dottedLine {
  align-self: stretch;
  // height: 1px;
  border: 1px solid #e1e4eb;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.div2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.element {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
  width: 100%;
}

.question {
  align-items: flex-start;
  display: flex;
  gap: 25px;
  position: relative;
  width: 260px;
}

.p {
  color: $blue;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
  text-wrap: balance;
  font-size: $fs-16;
}

.ans {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 25px;
  position: relative;
}

.textWrapper2 {
  color: $black;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
  text-wrap: balance;
  font-size: $fs-16;
}

.textWrapper4   {
  color: $black;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
  text-wrap: balance;
  font-size: $fs-16;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: $dark-gray;
}

.element2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
  width: 100%;
}

.textWrapper3 {
  color: $blue;
  flex: 1;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}
