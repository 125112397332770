@import 'src/Styles/Mixin/index.scss';
@import 'src/Styles/Variable/index.scss';

.CardBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  width: 23.5%;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #f1f1f4;
  &:hover {
    color: $white;
    background-color: $darkMain;
    img {
      filter: brightness(0) invert(1);
    }
  }
}

.ImgBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ImgBoxTwo {
  display: flex;
  gap: 4px;
}

.ImgText {
  font-family: $SfUi-semibold;
  font-size: 36px;
  font-weight: 500;
  line-height: 50px;
  text-align: left;
}

.ImgTextTwo {
  //styleName: Text Medium/Medium;
  font-family: $SfPro-medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #99a1b7;
  text-wrap: pretty;
}
