@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';
$bold-ff: 'SF Pro DisplayBold', Helvetica;
$regular-ff: 'SF Pro Display-Regular', Helvetica;

// Define mixins
@mixin mid-text($size, $weight, $height) {
  font-family: $regular-ff;
  font-size: $size;
  font-weight: $weight;
  letter-spacing: 0;
  line-height: $height;
}
.container {
  @include flex(column);
  padding: 30px;
  gap: 20px;
}

.head {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
}

.AvatarBox {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SubGrid {
  @include flex-row;
  @include flex-gap(30px);
  @include stretch-width;
  align-items: center;
}

.MainGrid {
  background-color: $white;
  border-radius: 20px;
  width: 100%;
}

.ContentBox {
  @include flex-start;
  @include flex-column;
  @include flex-grow;
  @include flex-gap(20px);
  @include relative-position;
}
.ContentHeadBox {
  @include flex-start;
  @include flex-row;
  @include stretch-self;
  justify-content: space-between;
  @include relative-position;
}

.ContentHead {
  color: $subGrayText;
  @include mid-text(22px, 400, 30px);
}

.ContentHeadNameText {
  color: $black;
  font-family: $bold-ff;
  font-size: $fs-28;
  font-weight: $fw-bold;
  letter-spacing: 0;
  line-height: 40px;
}

.ContentTypeBox {
  @include flex-center;
  @include flex-row;
  background-color: #f1eeff;
  border-radius: 100px;
  @include flex-auto;
  @include flex-gap(12px);
  justify-content: center;
  padding: 4px 16px;
  margin-top: -8px;
  @include relative-position;
}

.ContentText {
  color: $subGrayText;
  @include mid-text(16px, 400, 24px);
  @include relative-position;
  @include fit-content;
  margin-top: -1px;
  white-space: nowrap;
}

.HeadText {
  color: #190482;
  font-family: $SfPro-semibold;
  font-size: $fs-24;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.SubText {
  font-size: 14px;
  color: #99a1b7;
  font-family: $SfPro-regular;
  font-weight: $fw-medium;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-align: center;
}

.title {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.textWrapper {
  color: $themeMain;
  font-family: $SfUi-semibold;
  font-size: $fs-24;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.breadcrumb {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.fontWrapper {
  color: $darker-gray;
  font-family: $SfPro-regular;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.main {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  position: relative;
  width: 100%;
}

.profileDetail {
  align-items: flex-start;
  background-color: $white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 30px;
  position: relative;
  width: 620px;
}

.dottedLine {
  width: 100%;
  height: 1px;
  border: 1px dashed #c4cada;
}

.flexBox {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.image {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.imageBox {
  background-blend-mode: luminosity;
  border-radius: 50%;
  height: 120px;
  position: relative;
  width: 120px;
}

.info {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.flexCenter {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.role {
  align-items: center;
  background-color: #c8c9cc80;
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  overflow: hidden;
  padding: 2px 8px;
  position: relative;
}

.smallTextWrapper {
  color: $darker-gray;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.blackTextWrapper {
  color: $black;
  font-family: $SfPro-medium;
  font-size: $fs-20;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.editButton {
  margin: 0;
  height: auto;
  padding: 0;
  line-height: 40px;
  color: $themeMain;
  background: $lightMain;
  border-radius: 8px;
}

.semiBoldTextWrapper {
  color: $black;
  font-family: $SfPro-semibold;
  font-size: $fs-20;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 30px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.list {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.listItems {
  background-color: $white;
  border: 1px solid;
  border-color: #f1f1f4;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  width: 100vh;
}

.midBlckText {
  color: $black;
  font-family: $SfPro-medium;
  font-size: $fs-34;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 50px;
  opacity: 0.8;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.smallGrayText {
  color: $subGrayText;
  font-family: $SfPro-regular;
  font-size: $fs-18;
  font-weight: $fw-regular;
  letter-spacing: 0;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  line-height: 24px;
  position: relative;
  width: fit-content;
}

.smallGrayBox {
  display: flex;
  background-color: $lightMain;
  border-radius: 30px;
  padding: 2px 10px;
}

.spacedHead {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.centerTitle {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  position: relative;
}

.justifiedBox {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.application {
  align-items: flex-start;
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.tabs {
  font-family: $SfUi-semibold;
  font-size: 18px;
  line-height: 24px;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  padding: 30px 30px 10px;
  position: relative;
  width: 100%;
  color: $dark-gray;
}

.inActive {
  cursor: pointer;
}

.active {
  cursor: pointer;
  color: $themeMain;
  border-bottom: 2px solid;
}

.divWrapper {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e1e4eb;
  display: flex;
  gap: 10px;
  height: 70px;
  padding: 15px 30px;
  position: relative;
  width: 100%;
}

.action {
  align-items: center;
  align-self: stretch;
  border-color: #e1e4eb;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: flex-end;
  padding: 20px 30px;
  position: relative;
  width: 100%;
}

.button {
  all: unset;
  align-items: center;
  background-color: $dark-gray;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 13px 15px;
  position: relative;
  width: 160px;
}

.blackText {
  color: $themeMain;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  opacity: 0.7;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.darkButton {
  all: unset;
  align-items: center;
  background-color: $themeMain;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 13px 15px;
  position: relative;
  width: 160px;
}

.whiteText {
  color: $white;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
