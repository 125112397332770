@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.MainBox {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  margin: 30px;

}

.label {
  margin: 10px 0px;
  color: $black;
  // background-color: $white-variant-2;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-bold;
}

.checkBoxLabel {
    color: $black;
  // background-color: $white-variant-2;
  font-family: $SfPro-semibold;
  font-size: $fs-18;
  line-height: 24px;
  font-weight: 600;
  text-wrap: wrap;
  gap:4px;
}

.profileBox {
  // margin: 30px 0px;
  border: 1px solid #e1e4eb;
  border-radius: 20px;
  padding: 20px;
  margin-top:16px;
  margin-bottom: 16px;
}
.title {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 100%;
}

.main {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.textWrapper {
  color: $black;
  font-family: $SfPro-semibold;
  font-size: $fs-20;
  font-weight: $fw-semi-medium;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
}
.SubTitle {
  color: $black;;
  font-family: $SfPro-semibold;
  font-size: $fs-16;
  font-weight: $fw-semi-medium;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
}

.button {
  all: unset;
  align-items: flex-start;
  background-color: $gray;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 6px 15px;
  position: relative;
  background-color: #F9F9F9;
}

.AddButton {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0px;
  gap: 24px;
}
.ButtonOne {
  width: auto;
  padding: 13px 50px;
  background-color: #f1f1f4;
  color: $black;
}
.ButtonTwo {
  width: auto;
  padding: 13px 50px;
  background-color: #7752fe;
  color: $white;
}


.div {
color: $darker-gray;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
  padding: 4px 10px;
  border-radius: 8px;
}

.dottedLine {
  align-self: stretch;
  // height: 1px;
  border: 1px solid #e1e4eb;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.div2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.element {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
  width: 100%;
}

.question {
  align-items: flex-start;
  display: flex;
  gap: 25px;
  position: relative;
  width: 260px;
}

.p {
  color: $blue;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
  text-wrap: balance;
  font-size: $fs-16;
}

.ans {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 25px;
  position: relative;
}

.textWrapper2 {
  color: $black;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
  text-wrap: balance;
  font-size: $fs-16;
}

.flagTextWrapper2 {
  color: $dark-red;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
  text-wrap: balance;
  font-size: $fs-16;
}



.textWrapper4   {
  color: $black;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
  text-wrap: balance;
  font-size: $fs-16;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: $dark-gray;
}

.element2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
  width: 100%;
}

.textWrapper3 {
  color: $blue;
  flex: 1;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

// Current and Past Employer
.card {
  align-items: flex-start;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  position: relative;
  margin: 10px 0px;
  width: -webkit-fill-available;
}

.cardDiv {
  align-items: flex-start;
  // backgroundcolor: $white;
  border: 2px dashed;
  border-color: #a4a4a7;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;
  padding: 30px;
  position: relative;
  width: -webkit-fill-available;
}

.cardFrame {
  align-items: flex-start;
  // align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  position: relative;
  // width: 100%;
}

.cardElement {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
}

.cardQuestion {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 25px;
  position: relative;
}

.cardTextWrapper {
  color: $blue;
  flex: 1;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.cardDivWrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 25px;
  position: relative;
  width: 100%;
}

.cardTextWrapper2   {
  color: $black;
  flex: 1;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}
.flagCardTextWrapper2   {
  color: $dark-red;
  flex: 1;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}


// .cardFrame2 {
//   align-items: flex-start;
//   align-self: stretch;
//   display: flex;
//   gap: 30px;
//   position: relative;
//   width: 90%;
// }

// .cardElement2 {
//   align-items: flex-start;
//   display: flex;
//   flex: 1;
//   flex-direction: column;
//   flex-grow: 1;
//   position: relative;
// }

.cardAns {
flex-wrap: wrap;
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 25px;
  position: relative;
}

.cardTextWrapper3 {
  color: $black;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.flagCardTextWrapper3 {
  color: $dark-red;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.cardElement3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 44px;
  position: relative;
}

// .cardTextWrapper4 {
//   color: $themeMain;
//   font-family: $SfPro-medium;
//   font-size: $fs-16;
//   font-weight: $fw-medium;

//   letter-spacing: 0;
//   line-height: 20px;
//
//   position: relative;
//   white-space: nowrap;
//   width: fit-content;
// }
