@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.ContactBox {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ContactnGrid {
  display: flex;
  justify-content: center;
  align-items: center;
}
.AddButton {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0px;
  gap: 24px;
}

.ButtonOne {
  width: auto;
  padding: 13px 50px;
  background-color: #f1f1f4;
  color: $black;
}
.ButtonTwo {
  width: auto;
  padding: 13px 50px;
  background-color: #7752fe;
  color: $white;
}


.ContactTitle {
  font-family: $SfPro-regular;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #190482;
}

.DialogContentBox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  // margin: 30px 100px;
  gap: 10px;
}

.Chip {
  display: flex;
}

.ChipBox {
  display: flex;
  gap: 6px;
  padding: 0 16px;
  flex-wrap: wrap;
}

.overlapGroup {
  height: 180px;
  left: -2px;
  position: relative;
  top: -10px;
  width: 172px;
}

.Rectangle {
  background-blend-mode: luminosity;
  height: 164px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 164px;
  background-color: $dark-gray;
  border-radius: 10px;
}

.nameWrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  background-color: #e1e4eb;
  border-radius: 30px;
  display: block;
  padding: 2px 8px;
  position: absolute;
  bottom: 0;
  width: 164px;
}

.IconDeleteWrapper {
  align-items: center;
  background-color: #e1e4eb;
  border-radius: 30px;
  display: flex;
  gap: 10px;
  height: 32px;
  justify-content: center;
  left: 140px;
  padding: 4px;
  position: absolute;
  top: 0;
  width: 32px;
  cursor: pointer;
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  padding: 10px 10px;
}

.GridBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  background-color: #fff;
  border-radius: 30px;
  // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.label {
  color: $black;
  // background-color: $white-variant-2;
  font-family: $SfPro-semibold;
  font-size: $fs-18;
  line-height: 24px;
  font-weight: 600;
}

.subLabel {
  color: $black;
  // background-color: $white-variant-2;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  line-height: 24px;
  font-weight: 500;
}



.ButtonOne {
  width: auto;
  padding: 13px 50px;
  background-color: #f1f1f4;
  color: $black;
}
.ButtonTwo {
  width: auto;
  padding: 13px 50px;
  background-color: #7752fe;
  color: $white;
}

.cardContent {
  width: 100%;
  display: flex;
  gap: 20px;
  padding: 16px 0;
}

.cardDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cardBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  border: 1px dashed rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  height: 268px;
  background-color: #f1eeff;
  padding: 20px;
  gap: 20px;
  cursor: pointer;
  flex-direction: column;
}
