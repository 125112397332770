@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';


//********************

.Box1 {
  align-items: center;
  background-color: $lightGray;
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  padding: 8px 15px;
  position: relative;
}
.Add2 {
  align-items: center;
  width: fit-content;
  background-color: $lightGray;
  border-radius: 10px;
  display: inline-flex;
  padding: 8px 20px;
  margin: 0;
  height: auto;
}
.Add {
  align-items: center;
  width: fit-content;
  background-color: $themeMain;
  border-radius: 10px;
  display: inline-flex;
  padding: 8px 20px;
  margin: 0;
  height: auto;
}
.Buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 10px;
}
.filterMenu {
  ul {
    width: 300px;
    background-color: white;
    border-radius: 12px;
  }
  li {
    fieldset {
      border: none;
    }
  }
}

.fullWidth {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.titleWrapper {
  width: 100%;
  display: flex;
  border-bottom: 1px solid $lightGray;
}
.inField {
  font-size: 14px;
  padding: 7px;
  background-color: $gray;
  @extend %field;
}


.titleText {
  display: flex;
  color: $black;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.Main2 {
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  justify-content: center;
  position: relative;
}

.MidText {
  color: #4a5568;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.bar,
.titleParent {
  display: flex;
  justify-content: flex-start;
}

.bar {
  align-self: stretch;
  border-radius: 30px;
  flex-direction: row;
  align-items: center;
}
.titleParent {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px 0;
  min-width: 177px;
}


.titleBar {
  align-self: stretch;
  justify-content: space-between;
  gap: 20px;
}

.applicationCompletion,
.div {
  position: relative;
  line-height: 20px;
}

.div {
  line-height: 16px;
  font-weight: $fw-medium;
}

.titleBar,
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.wrapper {
  width: 32px;
  border-radius: 4px;
  background-color: #e3e3e5;
  justify-content: center;
  font-size: $fs-12;
}
//*********************
.manageApplication {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: $fw-semi-medium;
  font-family: inherit;
  white-space: nowrap;
}




.applications {
  position: relative;
  line-height: 20px;
}
.breadcrumb,
.title {
  display: flex;
  justify-content: flex-start;
}
.breadcrumb {
  flex-direction: row;
  align-items: center;
  text-align: center;
    font-weight: $fw-medium;

color: $darker-gray;
}
.title {
  flex-direction: column;
  align-items: flex-start;
}
.applications26 {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: $fw-semi-medium;
  font-family: inherit;
  white-space: nowrap;
}
.icon {
  height: 35px;
  width: 0;
  position: relative;
  object-fit: contain;
  display: none;
}
.search {
  border: 0;
  background-color: transparent;
  height: 40px;
  flex: 1;
  width: 300px;
  font-weight: $fw-medium;
  font-size: $fs-16;
  color: $blue;
}
.listChild,
.listInner,
.listItem,
.rectangleIcon {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 40px;
  object-fit: cover;
  min-height: 40px;
}
.listInner,
.listItem,
.rectangleIcon {
  object-fit: contain;
  margin-left: -9px;
}
.rectangleIcon {
  object-fit: cover;
}
.div,
.holder {
  position: absolute;
}
.holder {
  top: 0;
  left: 0;
  border-radius: 40px;
  background-color: #e3e3e5;
  border: 2px solid #fff;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.div {
  top: 8px;
  left: 8px;
  line-height: 24px;
  font-weight: $fw-medium;
  white-space: nowrap;
}
.counter {
  height: 40px;
  width: 40px;
  position: relative;
  margin-left: -9px;
}
.left,
.list {
  flex-direction: row;
  justify-content: flex-start;
}
.list {
  display: none;
  align-items: flex-start;
  font-size: $fs-16;
color: $darker-gray;
}
.left {
  width: 470px;
  display: flex;
  align-items: center;
  gap: 0 15px;
  max-width: 100%;
}
.arrangeSquare2Icon {
  height: 16px;
  width: 16px;
  position: relative;
}
.main,
.sort1 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.sort1 {
  position: relative;
  line-height: 24px;
  font-weight: $fw-medium;
}
.main {
  flex-direction: row;
  justify-content: center;
  gap: 0 6px;
}
.iconchevronDown1 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.filter,
.sort {
  align-self: stretch;
}
.sort {
  border-radius: 10px;
  background-color: #f8f9fb;
  border: 1px solid #c8c9cc;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  gap: 0 12px;
}
.filter {
  width: 115px;
  height: 40px;
  margin-top: 4px;
  text-transform: 'none';
   color: #6f737a;
   font-size: 16px;
   background: #f8f9fb;
   border-radius: 10px;
   width: 115;
}
.add {
  height: 40px;
  flex: 1;
  text-transform: 'none';
  color: #fff;
  font-size: 16px;
  background: themeMain;
  border-radius: 10px;
  height: 40;
  margin: 0;
  align-items: center;
  padding-right: 24px;
  padding-left: 24px;
}

.AddButton {
  display: flex;
  gap: 10px;
  margin: 0px;
}

.ButtonOne {
  width: auto;
  height: 40px;
  padding: 0 24px;
  margin: 0;
  background-color: #f1f1f4;
  color: $black;
}
.ButtonTwo {
  width: auto;
  height: 40px;
  margin: 0;
  padding: 0 24px;
  font-size: '16';
  border-radius: '10px';
  background-color: #7752fe;
  &:hover{
    background-color: #7752fe;
  }
  color: $white;
}

.action,
.head,
.right {
  align-self: stretch;
  display: flex;
}
.right {
  width: 430px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 15px;
  font-size: $fs-16;
color: $darker-gray;
}
.action,
.head {
  max-width: 100%;
}
.action {
  height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  font-size: $fs-20;
}
.head {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  box-sizing: border-box;
  gap: 20px 0;
  text-align: left;
  font-size: $fs-24;
  color: $themeMain;

}
