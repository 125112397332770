@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.TableHead {
  th {
    font-family: $SfPro-bold;
    font-size: $fs-12;
    line-height: $fs-16;
    font-weight: 600;
    color: $sub-text-Gray;
  }
}

.planSwitch {
  justify-content: center;
  border-radius: 10px;
  border: 1px dashed $gray;
  background-color: $white;
  align-self: start;
  display: flex;
  gap: 15px;
  font-size: $fs-16;
  font-weight: $fw-medium;
  white-space: nowrap;
  line-height: 150%;
  padding: 5px 20px;
}

.planOption {
  font-family: $SfPro-regular;
  border-radius: 8px;
  background-color: $white;
  flex-grow: 1;
  justify-content: center;
  color: $themeMain;
  padding: 8px 15px;
}
.planSelectedOption {
  font-family: $SfPro-regular;
  border-radius: 8px;
  background-color: $gray;
  flex-grow: 1;
  justify-content: center;
  color: $themeMain;
  padding: 8px 15px;
}
.error {
  font-size: $fs-18;
}

.planCard {
  justify-content: space-between;
  border-radius: 15px;
  border: 1px dashed $gray;
  background-color: $white;
  display: flex;
  width: 100%;
  gap: 20px;
  white-space: nowrap;
  padding: 20px;
  cursor: pointer;
}
.selectedCard {
  justify-content: space-between;
  border-radius: 15px;
  border: 1px dashed $gray;
  background-color: #f1eeff;
  display: flex;
  width: 100%;
  gap: 20px;
  white-space: nowrap;
  padding: 20px;
  cursor: pointer;
}

.planHeader {
  align-self: start;
  display: flex;
  padding-right: 14px;
  gap: 20px;
}

.planCheckbox {
  border-radius: 12px;
  background-color: $dark-gray;
  width: 24px;
  height: 24px;
  margin: auto 0;
}

.planCheckedbox {
  border-radius: 12px;
  background-color: $themeMain;
  width: 25px;
  height: 25px;
  margin: auto 0;
  border: 4px solid #fff;
}

.planCheckbox > span,
.planCheckedbox > span {
  display: none;
}

.planInfo {
  justify-content: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}

.planName {
  color: $themeMain;
  font: 600 20px/120% $SfPro-regular;
}

.planPrice {
  color: $dark-gray;
  font: 500 16px/150% $SfPro-regular;
}

.planFooter {
  align-items: baseline;
  display: flex;
  gap: 4px;
  font-size: $fs-20;
  color: $themeMain;
  font-weight: $fw-medium;
  line-height: 120%;
}

.planCurrency {
  font-family: $SfPro-regular;
}

.planAmount {
  font-family: $SfPro-regular;
  font-size: $fs-36;
}

.planPeriod {
  color: $dark-gray;
  font-family: $SfPro-regular;
}

.button {
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: $themeMain;
  margin-top: 40px;
  width: 100%;
  color: $white;
  white-space: nowrap;
  padding: 13px 60px;
  font: 500 16px/150% $SfPro-regular;
}

.holder2 {
  background-color: #1a202c;
  border-radius: 12px;
  height: 17px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 17px;
  z-index: 1;
}

.holder3 {
  background-color: #ff0000;
  border-radius: 12px;
  height: 26px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: 26px;
  z-index: 0;
}

.DialogTitle {
  border-bottom: 1px solid $themeMain;
  display: flex;
  justify-content: space-between;
}

.DialogContentBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 30px;
  padding: 30px 100px;
  gap: 20px;
}

.DialogProps {
  border-radius: 20px;
  height: fit-content;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  background-color: $white;
}

.currentPlan{
  color: $themeMain;
  background-color: $gray;
  padding: 4px 12px;
  border-radius: 4px;
}