@import '/src/Styles/Variable/index.scss';
@import '/src/Styles/Mixin/index.scss';

.containerHeight {
  height: calc(100vh);
  position: fixed;
  background-size: contain;
  justify-content: flex-end;
  display: flex;
  margin: 0;
}
@media (max-width: 1100px) {
  .containerHeight {
    position: absolute;
  }
}
.secondContainer {
  display: grid;
  justify-content: center;
  justify-items: center;
  height: calc(100vh - 20px);
  min-height: '-webkit-fill-available';
  overflow: auto;
}

.subTitle {
  color: #99a1b7;
}

.wBox {
  width: 460px;
}
.bgCmn1 {
  background-color: $white;
}
.bgCmn7 {
  background-color: $white-variant-1;
}
.br20 {
  border-radius: 10px;
}
.p10 {
  padding: 10px;
}

.theBox {
  border-radius: 10px;
  background-color: $yellowFill;
  border: 1px dotted $yellowStrock;
}

.center {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}

.textFieldWrapper {
  display: flex;
  color: $black;
  font-weight: 600;
  .FF002E {
    color: $red;
  }
}

.prvButton {
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: #f9f9f9;
  width: 100%;
  color: $black;
  white-space: nowrap;
  padding: 13px 60px;
  font: 500 16px/150% $SfPro-regular;
}

.button {
  all: unset;
  @include flex();
  @include border-radius(15px);
  box-sizing: border-box;
  align-self: stretch;
  background-color: $themeMain;
  flex: 0 0 auto;
  gap: 10px;
  padding: 13px 15px;
  position: relative;
  width: 100%;
  color: $white;

  &:hover {
    cursor: pointer;
    color: $themeMain;
    box-shadow:
      1px 1px 2px $themeMain,
      inset 1px 1px 3px $themeMain;
  }
}

.flexStartBox {
  @include flex(column, flex-start, center);
  @include border-radius();
  align-self: stretch;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.field {
  @extend %field;
}
