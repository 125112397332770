@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

%textWrapper {
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.prvButton {
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: #f9f9f9;
  margin-top: 40px;
  width: 100%;
  color: $black;
  white-space: nowrap;
  padding: 13px 60px;
  font: 500 16px/150% $SfPro-regular;
}

.Application {
  @include flex(row, flex-end);
  flex: 0 0 auto;
  gap: 0;
  // cursor: pointer;
  overflow: hidden;
}

.Sidebar {
  overflow: auto;
}

.billingText {
  align-self: stretch;
  color: $black;
  font-family: $SfUi-semibold;
  font-size: $fs-28;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: -1px;
  position: relative;
}
.button {
  all: unset;
  @include flex();
  @include border-radius(15px);
  box-sizing: border-box;
  align-self: stretch;
  background-color: $themeMain;
  flex: 0 0 auto;
  gap: 10px;
  padding: 13px 15px;
  position: relative;
  width: 100%;
  color: $white;

  &:hover {
    cursor: pointer;
    color: $themeMain;
    box-shadow:
      1px 1px 2px $themeMain,
      inset 1px 1px 3px $themeMain;
  }
}

.mediumText {
  @extend %textWrapper;
  margin-top: -1.5px;
}

.textFieldWrapper {
  @extend %textWrapper;
  color: $themeMain;
  margin-top: -1px;
}

.textWrapper8 {
  @extend %textWrapper;
  margin-top: -1.5px;
  color: $themeMain;
}

.Application {
  @include flex(column, flex-start);
  background-color: $themeMain;
  height: 100vh;
  width: 100vw;
}

.Application {
  .container2 {
    flex: 1;
    flex-grow: 1;
    height: 100vh;
    width: calc(100vw - 500px);
    padding: 10px;
    overflow: auto;
    position: sticky;
    top: 0;
  }

  .container2 {
    .background {
      @include flex(column, center, center);
      @include border-radius(30px);
      background-color: $white;
      height: 100%;
      width: 100%;
      overflow-y: auto;
    }

    .main2 {
      @include flex(column, center, center);
      width: 80%;
      // gap: 40px;
    }

    @media only screen and (min-height: 600px) {
      .background {
        @include flex(column, center, flex-start);
      }

      .main2 {
        padding: 50px 0px;
      }
    }

    @media only screen and (max-width: 1050px) {
      .main2 {
        width: 100%;
      }
    }
  }
}

.cancelButton {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
}
