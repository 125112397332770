@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.head {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
}

.HeadText {
  color: #190482;
  font-family: $SfPro-semibold;
  font-size: $fs-24;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.SubText {
  font-size: 14px;
  color: #99a1b7;
  font-family: $SfPro-regular;
  font-weight: $fw-medium;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-align: center;
  cursor: pointer;
}
