@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.FontBold{
  font-weight: $fw-bold;
}
.Main {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  position: relative;
  padding: 30px;
}

.Head {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 30px;
  position: relative;
  width: 100%;
}
.Main {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  position: relative;
  padding: 30px;
}

.HeadText {
  color: #190482;
  font-family: $SfPro-semibold;
  font-size: $fs-24;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.SubText {
  font-size: 14px;
  color: #99a1b7;
  font-family: $SfPro-regular;
  font-weight: $fw-medium;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-align: center;
}

.Action {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.Left {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  position: relative;
}
.SearchBox {
  align-items: center;
  background-color: $white;
  border: 1px solid;
  border-color: $dark-gray;
  border-radius: 10px;
  display: flex;
  gap: 6px;
  padding: 8px 15px;
  position: relative;
  width: 300px;
}

.SearchEm {
  background: transparent;
  border: none;
  border-bottom: none;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
}

.SearchEm > input {
  padding: 0;
}
.Right {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  height: 40px;
  position: relative;
}
.Box1 {
  align-items: center;
  background-color: $lightGray;
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  padding: 8px 15px;
  position: relative;
}

.Main2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  justify-content: center;
  position: relative;
}
.MidText {
  color: #4a5568;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.Add {
  align-items: center;
  width: fit-content;
  background-color: $themeMain;
  border-radius: 10px;
  display: inline-flex;
  padding: 8px 20px;
  margin: 0;
  height: auto;
}
.ListWrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  position: relative;
  width: 100%;
}

.ListGridMain {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.ListText{
  color: $blueMain;
  font-family: $SfPro-semibold;
  font-size: $fs-18;
  font-weight: $fw-regular;
  line-height: 24px;
}

.ListGrid {
  align-items: flex-start;
  display: flex;
  flex: 1;
  gap: 20px 20px;
  position: relative;
  border-radius: 15px;
  flex-grow: 1;
  flex-wrap: wrap;
}

.Head {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
  position: relative;
  width: 100%;
}

.HeadText {
  color: #190482;
  font-family: $SfPro-semibold;
  font-size: $fs-24;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stepNumber {
  color: #78829D;
  font-size: 12px;
}

.alignItemCenter{
  align-items: center;
}
.EditBtnStyle {
  border-radius: 8px;
  height: 40px;
  background-color: $gray;
  font-size: $fs-16;
}
.grayFonts{
  color:$blue
}

.MediumBlackFonts{
  color:#252F4A;
  font-weight: $fw-medium;
}
.redFlagFonts{
  color:$dark-red;
  font-weight: $fw-medium;
  font-family: $SfPro-medium;
}

.form-row-container {
    margin-top: 8px;
    margin-bottom: 8px;
    
  .form-row-item {
    padding: 10px 0px;
    .MuiGrid-root.MuiGrid-item {
      width: -webkit-fill-available;
    }
  }
}


.Element {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  position: relative;
  width: 100%;
}

.Question {
  align-items: center;
  display: flex;
  gap: 25px;
  position: relative;
  width: 460px;
}

.EditContactDiv {
  color: #071437;
  font-family: $SfPro-semibold;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.Ans {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  position: relative;
}
.AnsTxt {
  input {
    color: #78829d;
    background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
  div[role='combobox'] {
    color: #78829d;
    background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
  input[disabled] {
    color: #78829d;
    background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
}

.EditContactImage {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.EditContactLogo {
  border: 1px solid;
  border-color: #e1e4eb;
  border-radius: 15px;
  height: 160px;
  position: relative;
  width: 160px;
}

.overlapGroup {
  height: 180px;
  left: -2px;
  position: relative;
  top: -10px;
  width: 172px;
}

.Rectangle {
  background-blend-mode: luminosity;
  height: 164px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 164px;
  background-color: $dark-gray;
  border-radius: 10px;
}

.IconEditWrapper {
  align-items: center;
  background-color: #e1e4eb;
  border-radius: 30px;
  display: flex;
  gap: 10px;
  height: 32px;
  justify-content: center;
  left: 140px;
  padding: 4px;
  position: absolute;
  top: 0;
  width: 32px;
  cursor: pointer;

  .EditIcon {
    cursor: pointer;
  }
}

.inputFile {
  cursor: pointer;
  display: flex;
  position: absolute;
  height: 32px;
  width: 32px;
  opacity: 0;
}
::-webkit-file-upload-button {
  display: none;
}

.IconInstanceNode {
  height: 16px;
  position: relative;
  width: 16px;
}

.IconDeleteWrapper {
  align-items: center;
  background-color: #e1e4eb;
  border-radius: 30px;
  display: flex;
  gap: 10px;
  height: 32px;
  justify-content: center;
  left: 140px;
  padding: 4px;
  position: absolute;
  top: 148px;
  width: 32px;
  cursor: pointer;
}

.edit-contact .icon-delete {
  height: 24px;
  position: relative;
  width: 24px;
}

.Description {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.DescriptionP {
  color: $blue;
  font-family: $SfPro-regular;
  font-weight: $fw-medium;

  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.Action {
  align-items: center;
  align-self: stretch;
  border-color: #e1e4eb;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: flex-end;
  padding: 20px 30px;
  position: relative;
  width: 100%;
  border-top: dashed 1px #6a90eb;
  margin-top: 100px;
}

.ActionButton {
  all: unset;
  align-items: center;
  background-color: $dark-gray;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 13px 15px;
  position: relative;
  width: 160px;
  cursor: pointer;
}

.TextWrapper3 {
  color: $themeMain;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  opacity: 0.7;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.DivWrapper {
  all: unset;
  align-items: center;
  background-color: $themeMain;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 13px 15px;
  position: relative;
  width: 160px;
    cursor: pointer;

}

.TextWrapper4 {
  color: $white;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}