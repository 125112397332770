@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.tabs {
  font-family: $SfUi-semibold;
  font-size: 18px;
  line-height: 24px;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  padding: 30px 30px 10px;
  position: relative;
  width: 100%;
  color: $dark-gray;
}

.inActive {
  cursor: pointer;
}

.active {
  cursor: pointer;
  color: $themeMain;
  border-bottom: 2px solid;
}

.box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.timeWrapper {
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  background-color: $gray;
  padding: 2px 8px;
  color: #484d56;
  box-sizing: border-box;
}

%status {
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  width: -webkit-fill-available;
  flex: 0 0 auto;
  gap: 10px;
  padding: 8px 15px;
  box-sizing: border-box;
}

.Completed {
  @extend %status;
  background-color: $darker-gray;
  color: $white-variant-1;
}

.Rejected {
  @extend %status;
  background-color: $coffee;
  color: $white-variant-2;
}

.Flagged {
  @extend %status;
  background-color: #484d56;
  color: $gray;
}

.progressText {
  @extend %status;
  background-color: $gray;
  color: #484d56;
}

.tableHead {
  color: $dark-gray;
}

.form {
  align-items: flex-start;
  align-self: stretch;
  background-color: $white;
  border-radius: 20px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  position: relative;
  width: 100%;
}

.element {
  align-self: stretch;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.field {
    font-size: 14px;
  @extend %field;
  flex: 1;
  flex-grow: 1;
}

.textFieldWrapper {
  display: flex;
  flex: 1;
  flex-grow: 1;
  color: $themeMain;

  .FF002E {
    color: $red;
  }
}

.question {
  align-items: center;
  display: flex;
  gap: 25px;
  position: relative;
  width: 460px;
}

.logoImage {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.logo {
  border: 1px solid;
  border-color: #e1e4eb;
  border-radius: 15px;
  height: 160px;
  position: relative;
  width: 160px;
}

.img {
  background-blend-mode: luminosity;

  border-radius: 50%;
  height: 90px;
  left: 35px;
  position: absolute;
  top: 35px;
  width: 90px;
}

.iconEditWrapper {
  align-items: center;
  background-color: #e1e4eb;
  border-radius: 30px;
  display: flex;
  gap: 10px;
  height: 32px;
  justify-content: center;
  left: 138px;
  padding: 4px;
  position: absolute;
  top: -10px;
  width: 32px;
}

.iconDeleteWrapper {
  align-items: center;
  background-color: #e1e4eb;
  border-radius: 30px;
  display: flex;
  gap: 10px;
  height: 32px;
  justify-content: center;
  left: 138px;
  padding: 4px;
  position: absolute;
  top: 138px;
  width: 32px;
}

.iconDelete {
  height: 24px;
  position: relative;
  width: 24px;
}

.description {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.p {
  color: $blue;
  font-family: $SfPro-regular;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.span {
  color: $themeMain;
}

.toggleWrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.toggleText {
  color: $themeMain;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.switch {
  flex: 0 0 auto;
  position: relative;
}
