@import 'src/Styles/Mixin/index.scss';
@import 'src/Styles/Variable/index.scss';

.PersonalDetailBox {
  display: block;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
  width: 23.5%;
  border-radius: 20px;
  background-color: white;
  padding: 30px;
  border: 1px solid #f1f1f4;
}

.PersonalDetailTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $fs-20;
  font-family: $SfPro-semibold;
  margin-bottom: 30px;
}

.PersonalDetailSubBox {
  justify-content: space-between;
  margin-bottom: 30px;
}

.PersonalDetailName {
  font-family: $SfPro-medium;
}

.PersonalDetailRole {
  font-size: 16px;
  width: max-content;
  font-family: $SfUi-semibold;
  font-weight: 500;
  color: $themeMain;
  background-color: $lightMain;
  border-radius: 4px;
  margin: 1px;
  padding: 2px 6px;
}

.PersonalDetailDiv {
  // display: flex;
  // align-items: center;
  gap: 10px;
}

.PersonalDetailText {
  display: flex;
  align-items: center;
  font-size: $fs-14;
  max-width: max-content;
  font-family: $SfPro-semibold;
  font-weight: 500;
  color: #4b5675;
  border-radius: 6px;
  padding: 10px 0px;
}
