@import '/src/Styles/Mixin/index.scss';
@import '/src/Styles/Variable/index.scss';

.container {
  @include flex(column, flex-start, flex-start);
  flex-basis: auto;
  height: 100vh;
  padding: 60px 60px;
  width: 500px;
  gap: 40px;
}

.container {
  .main {
    @include flex(column, flex-start, center);
    align-self: stretch;
    position: relative;
    width: 100%;
    flex-basis: 0;
    padding-bottom: 40px;
  }

  .element {
    @include flex(row, center, center);
    align-self: stretch;
    background-color: transparent;
    padding: 0;
    position: relative;
    width: 100%;
    gap: 20px;
  }

  .counter {
    @include flex(row, center, center);
    @include border-radius(15px);
    background-color: $white;
    color: $black;
    height: 50px;
    padding: 12px 15px;
    max-width: 50px;
  }

  .textWrapper {
    flex: 1;
    font-family: $SfUi-semibold;
    font-size: $fs-18;
    font-weight: $fw-regular;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    text-align: center;
  }

  .title {
    @include flex(column, flex-start);
    flex: 1;
    flex-grow: 1;
    position: relative;
  }

  .div {
    @include flex(column, flex-start);
    align-self: stretch;
    color: $white;
    font-family: $SfUi-semibold;
    font-size: $fs-16 + 4px;
    font-weight: $fw-regular;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
  }

  .textWrapper2 {
    @include flex(column, flex-start);
    align-self: stretch;
    color: $dark-gray;
    font-family: $SfPro-regular;
    font-size: $fs-14;
    font-weight: $fw-regular;
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
  }

  .lineWrapper {
    @include flex(row, center, center);
    @include border-radius(15px);
    position: relative;
    width: 50px;
  }

  .linex {
    border-left: 1px dashed $dark-gray;
    height: 60px;
  }

  .divWrapper {
    @include flex(row, center, center);
    @include border-radius(15px);
    background-color: #00000033;
    color: $white;
    border: 1px dashed;
    border-color: $dark-gray;
    height: 50px;
    padding: 12px 15px;
    position: relative;
    max-width: 50px;
  }

  .logo {
    height: 48px;
    // margin: 10px 0px;
  }
}
