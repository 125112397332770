@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.iconmore {
  height: 24px;
  width: 24px;
  position: relative;
}

.frameParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  font-size: $fs-18;
  color: $themeMain;
  cursor: pointer;
}

.circle-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red; /* Change the color as needed */
  margin: 0 5px; /* Adjust the spacing as needed */
}

.iconlocation {
  height: 16px;
  width: 16px;
  position: relative;
}
.aAvenueJf {
  position: relative;
  line-height: 20px;
}
.location {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0 6px;
}
.phoneIcon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.searchFrame {
  position: relative;
  line-height: 20px;
}
.phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 6px;
}
.mail01Icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.ellyseperrygmailcom {
  position: relative;
  line-height: 20px;
  white-space: nowrap;
}
.email {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 6px;
}
.contact,
.info,
.infoWrapper {
  display: flex;
  align-items: flex-start;
}
.contact {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0 30px;
}
.info,
.infoWrapper {
  max-width: 100%;
}
.info {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: 8px 0;
}
.infoWrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 7px 0 0;
  box-sizing: border-box;
}
.applicationCompletion,
.div {
  position: relative;
  line-height: 20px;
}
.div {
  line-height: 16px;
  font-weight: $fw-medium;
}
.title,
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.wrapper {
  width: 32px;
  border-radius: 4px;
  background-color: #e3e3e5;
  justify-content: center;
  font-size: $fs-12;
}
.title {
  align-self: stretch;
  justify-content: space-between;
  gap: 20px;
}
.holder {
  height: 4px;
  width: 199px;
  position: relative;
  border-radius: 30px;
  background-color: $themeMain;
}
.bar,
.titleParent {
  display: flex;
  justify-content: flex-start;
}
.bar {
  align-self: stretch;
  border-radius: 30px;
  flex-direction: row;
  align-items: center;
}
.titleParent {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px 0;
  min-width: 177px;
}
.inProgress {
  position: relative;
  font-weight: $fw-medium;
  line-height: 20px;
  color: $blue;
  text-align: left;
}
.right {
  cursor: pointer;
  border: 0;
  padding: 8px 10px;
  background-color: #f1eeff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: $themeMain;
  gap: 5px;
  // width: 150px;
}
// .right:hover {
//   background-color: #d9dbde;
// }
.separatorFrame,
.separatorFrameInner,
.userParent {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.userParent {
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  gap: 0 12px;
}
.separatorFrame,
.separatorFrameInner {
  box-sizing: border-box;
}
.separatorFrameInner {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 7px 0 0;
}
.separatorFrame {
  flex: 1;
  border-radius: 20px;
  background-color: $white;
  flex-direction: column;
  align-items: center;
  padding: 30px 23px 30px 30px;
  gap: 20px 0;
  // min-width: 335px;
  text-align: left;
  font-weight: $fw-medium;
  color: $darker-gray;
}
@media screen and (max-width: 450px) {
  .contact {
    justify-content: center;
  }
  .contact,
  .title,
  .userParent {
    flex-wrap: wrap;
  }
}
