@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.WelcomeBox {
  // height: 100%;
  // justify-content: center;
  // align-items: center;
  // display: flex;
  // flex-grow: 1;
  // padding: 10px 0;
  // background-color: $themeMain;
}

.Form {
  width: 100%;
  padding: 10px 10px;
}

.GridBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  background-color: #fff;
  border-radius: 30px;
  // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.Heading {
  font-family: $SfPro-regular;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  text-align: left;
  color: $black;
}

.Title {
  color: #190482;
  margin: 12px;
  font-family: $SfPro-regular;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}

.SubTitle {
  color: #071437;
  margin: 12px;
  font-family: $SfPro-regular;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.HeadTxt {
  color: #78829d;
  margin: 12px;
  font-family: $SfPro-regular;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}

.LineTxt {
  color: #78829d;
  font-family: $SfPro-regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-decoration: underline;
}

.DialogContentBox {
  display: block;
  align-items: center;
  justify-content: flex-start;
  // margin: 30px 100px;
  gap: 10px;
}

.label {
  margin: 10px 0px;
  color: $black;
  // background-color: $white-variant-2;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-bold;
}

.AnsTxt {
  input {
    color: #8690ab;
    background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
  textarea {
    color: #8690ab;
    // background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
  div[role='combobox'] {
    color: #8690ab;
    background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
}

.star {
  margin: 8px;
}

.AddButton {
  display: flex;
 justify-content: flex-end;
  margin: 40px 0px;
  gap: 24px;

}

.ButtonOne {
  width: auto;
  padding: 13px 50px;
  background-color: #f1f1f4;
  color: $black;
}
.ButtonTwo {
  width: auto;
  padding: 13px 50px;
  background-color: #7752fe;
  color: $white;
}

.frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
  margin: 30px 0px;
}

.div-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.textWrapper {
  position: relative;
  width: fit-content;
  text-wrap: pretty;
  font-family: $SfPro-semibold;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: $black;
}

.element {
  align-self: stretch;
  flex-direction: row;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
}

.radioSelectionBoxes {
  margin: 0;
  flex: 1;
  display: flex;
  flex-grow: 1;
  width: 100vh;
  padding: 20px;
  border-radius: 20px;
  border: 1px dashed blue;
  .radioText {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: unset;
    // padding: 20px;
  }
}

.radioSelectionBoxSelect {
  margin: 0;
  flex: 1;
  display: flex;
  flex-grow: 1;
  width: 100vh;
  padding: 20px;
  border-radius: 20px;
  border: 1px dashed blue;
  background-color: #f1eeff;
  .radioText {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: unset;
    // padding: 20px;
  }
}

.TxtQ {
  font-family: $SfPro-regular;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: $black;
}

.TxtA {
  //styleName: Text Regular/Regular;
  font-family: $SfPro-regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #99a1b7;
}

.cardAddInstance {
  margin-top: 10px;
  align-self: stretch;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: #f1eeff;
  border-radius: 20px;
}

.addNamesButton {
  padding: 5px 30px;
  background-color: #7752fe;
  margin: 0;
}

.addNamesHeading {
  font-family: $SfPro-medium;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: $black;
}

.addNamesText {
  font-family: $SfPro-medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #78829d;
  padding: 6px 0px;
}

.profileBox {
  // margin: 30px 0px;
  border: 1px solid #e1e4eb;
  border-radius: 20px;
  padding: 20px;
}

.Check {
  color: $themeMain;
  &:has(input:checked) {
    color: $themeMain;
  }
}

.ContactDotCircleRound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #7752fe;
  box-sizing: border-box;
}

.ContactDotCircle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #7752fe;
}

.radioBox {
  display: flex;
  align-items: center;
  border: 1px dashed $themeMain;
  border-radius: 10px;
  width: 120px;
  padding: 8px 20px;
  gap: 10px;
  background-color: #f1eeff;
}
