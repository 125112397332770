@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.Button {
   &:hover {
 background-color: $themeMain;
  box-shadow: none;
}
  border-radius: 15px;
  background-color: $themeMain;
  color: $white;
  padding: 0px 30px;
  width: 160px;
}

.PrevButton {
  border-radius: 15px;
  background-color: #F1F1F4;
  color: $black;
  padding: 0px 30px;
  width: 160px;
   &:hover {
 background-color: #F1F1F4;
  box-shadow: none;
}
}
.listBox {
    background-color: #FFFF;
    border-radius: 10px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    padding: 20px; // Adjust padding as needed
}
.listItem {
  &:hover {
        background-color: #F1F1F4;
        cursor: pointer;// Change background color on hover
    }
}