@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';
// Define colors as constants

$primary-color: $themeMain;
$secondary-color: #6f737a;
$accent-color: $blue;
$background-color: #f8f9fb;
$border-color: $dark-gray;
$ellipse-color: #c8c9cc;

// Define fonts as constants
$main-font: 'SF UI Display-Semibold', Helvetica;
$secondary-font: 'SF Pro Display-Regular', Helvetica;
$main-font: 'SF Pro Display-Medium', Helvetica;
$main-font: 'SF Pro Display-Bold', Helvetica;
$secondary-font: 'SF Pro Display-Medium', Helvetica;

// Mixin for common styles
@mixin common-styles {
  font-family: $main-font;
  letter-spacing: 0;
  position: relative;
  white-space: break-spaces;
}

.organizations {
  height: 100%;
  position: relative;
  width: 100%;
  padding: 16px;

  // .body {
  //   @include common-styles;
  //   align-items: flex-start;
  //   border-radius: 30px 30px 0px 0px;
  //   display: flex;
  //   justify-content: flex-end;
  //   width: 100%;
  // }

  .container {
    @include common-styles;
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    height: 990px;
  }

  .main {
    @include common-styles;
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 30px;
    position: relative;
    width: 100%;
  }

  .div {
    @include common-styles;
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
  }

  .div-2 {
    @include common-styles;
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }

  .text-wrapper {
    color: $blueMain;
    font-size: $fs-24;
    font-weight: $fw-semi-medium;
    line-height: 32px;
    width: fit-content;
  }

  .div-3 {
    @include common-styles;
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }

  .text-wrapper-2 {
    color: $sub-text-Gray;
      font-weight: $fw-medium;
    font-family: $secondary-font;
    line-height: 20px;
    text-align: center;
    width: fit-content;
  }

  .icon-instance-node {
    height: 16px;
    position: relative;
    width: 16px;
  }

  .div-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  .left {
    @include common-styles;
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 15px;
    position: relative;
  }

  .text-wrapper-3 {
    color: $blueMain;
    font-weight: $fw-semi-medium;
    font-size: $fs-20;
    line-height: 32px;
    width: fit-content;
  }

  .SearchBox {
    @include common-styles;
    align-items: center;
    background-color: $gray;
    border: 1px solid;
    border-color: $dark-gray;
    border-radius: 10px;
    display: flex;
    gap: 6px;
    padding: 8px 15px;
    position: relative;
    width: 300px;
  }

  .SearchEm {
    background: transparent;
    border: none;
    border-bottom: none;
    font-family: $main-font;
    font-size: $fs-16;
    font-weight: $fw-medium;
    letter-spacing: 0;
  }

  .SearchEm > input {
    padding: 0;
  }

  .right {
    @include common-styles;
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 15px;
    height: 40px;
    position: relative;
  }

  .filter-2 {
    align-items: center;
    background-color: $background-color;
    border: 1px solid;
    border-color: $border-color;
    border-radius: 10px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    justify-content: center;
    padding: 8px 15px;
    position: relative;
  }

  .div-5 {
    @include common-styles;
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    justify-content: center;
    position: relative;
  }

  .div-6 {
    @include common-styles;
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    justify-content: center;
    position: relative;
  }

  .text-wrapper-4 {
    color: $secondary-color;
    font-size: $fs-16;
    font-weight: $fw-medium;
    line-height: 24px;
    width: fit-content;
  }

  .add {
    @include common-styles;
    align-items: center;
    align-self: stretch;
    background-color: $primary-color;
    border-radius: 10px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 8px 20px;
  }

  .Add {
    // color: $accent-color;
    // font-size: $fs-16;
    // font-weight: 500;
    // line-height: 24px;
    // margin-top: -1.5px;
    // width: fit-content;
    align-items: center;
    align-self: stretch;
    background-color: $themeMain;
    border-radius: 10px;
    display: inline-flex;
    gap: 10px;
    padding: 8px 20px;
    margin: 0;
    height: auto;
  }

  .list {
    @include common-styles;
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 30px;
    position: relative;
  }

  .list-2 {
    align-items: flex-start;
    border-radius: 15px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 30px 30px;
    position: relative;
  }

  .element {
    align-items: flex-start;
    background-color: $white;
    border-radius: 20px;
    display: grid;
    flex-direction: column;
    gap: 15px;
    padding: 30px;
    position: relative;
    width: 493px;
    height: 340px;
  }

  .head {
    @include common-styles;
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    position: relative;
  }

  .title {
    @include common-styles;
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 15px;
    position: relative;
  }

  .logo {
    border: 1px solid;
    border-color: $border-color;
    border-radius: 8px;
    height: 50px;
    position: relative;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-wrapper-6 {
    color: $primary-color;
    font-size: $fs-18;
    font-weight: $fw-bold;
    line-height: 30px;
    width: fit-content;
  }

  .location-2 {
    @include common-styles;
    align-items: center;
    display: inline-flex;
    flex: 1;
    flex-grow: 1;
    gap: 6px;
    justify-content: center;
    position: relative;
  }

  .text-wrapper-7 {
    color: $secondary-color;
    font-weight: $fw-medium;

    font-weight: $fw-regular;
    line-height: 20px;
    width: fit-content;
  }

  .icon-instance-node-2 {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }

  .description {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-around;
    position: relative;
    width: 100%;
  }

  .p {
    @include common-styles;
    align-self: stretch;
    color: $accent-color;
    flex: 1;
    font-weight: $fw-medium;
    font-weight: $fw-regular;
    line-height: 20px;
  }

  .text-wrapper-8 {
    color: $primary-color;
    font-size: $fs-16;
    font-weight: $fw-medium;
    line-height: 24px;
    width: fit-content;
  }

  .ellipse {
    background-color: $ellipse-color;
    border-radius: 2px;
    height: 4px;
    position: relative;
    width: 4px;
  }

  .right-2 {
    align-items: center;
    background-color: $background-color;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 2px 4px;
    position: relative;
  }

  .text-wrapper-9 {
    color: $accent-color;
    font-size: $fs-12;
    font-weight: $fw-regular;
    line-height: 16px;
    width: fit-content;
  }

  .info {
    @include common-styles;
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 30px;
    position: relative;
  }

  .input {
    @include common-styles;
    background: transparent;
    border: none;
    color: $secondary-color;
    font-weight: $fw-medium;
    font-weight: $fw-regular;
    line-height: 20px;
    padding: 0;
    width: fit-content;
  }
}

.div-7 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
