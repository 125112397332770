@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.Form {
  width: 80%;
  padding: 10px 10px;
}

.GridBox {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  background-color: #fff;
  border-radius: 30px;
  // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.Title {
  color: #190482;
  margin: 12px;
  font-family: $SfPro-regular;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}

.SubTitle {
  color: #071437;
  margin: 12px;
  font-family: $SfPro-regular;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.HeadTxt {
  color: $blueMain;
  font-family: $SfPro-regular;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.label {
  margin: 10px 0px;
  color: $black;
  // background-color: $white-variant-2;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-bold;
  text-wrap: pretty;
}

.star {
  margin: 8px;
}

.AddButton {
  display: flex;
  justify-content: space-between;
  margin: 40px 0px;
}

.ButtonOne {
  width: auto;
  padding: 13px 50px;
  background-color: #f1f1f4;
  color: $black;
}
.ButtonTwo {
  width: auto;
  padding: 13px 50px;
  background-color: #7752fe;
  color: $white;
}

.frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 30px;
}
