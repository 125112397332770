// Define variables for colors
$white: #ffffff;
$gray: #F9F9F9;
$dark-gray: #c8c9cc;
$subGrayText:#78829D;
$lightGray: #f1f1f4;
$black: #071437;
$lightMain:#F1EEFF;
$themeMain: #7752FE;
$darkMain:#441FCB;
$blueMain: #190482;
$green : #17C653;
$lightGreen : #DFFFEA;
$blue: #9d9fa4;
$red: #ff002d;
$darker-gray: #6f737a;
$white-variant-1: #f5f5f5;
$coffee: #433535;
$white-variant-2: #f8f9fb;
$dark-red:#d32f2f;
$yellowFill:#FFF8DD;
$yellowStrock: #F6C000;
$sub-text-Gray:#99A1B7;

//Font sizing
$fs-4: 4px;
$fs-6: 6px;
$fs-8: 8px;
$fs-10: 10px;
$fs-12: 12px;
$fs-14: 14px;
$fs-16: 16px;
$fs-18: 18px;
$fs-20: 20px;
$fs-22: 22px;
$fs-24: 24px;
$fs-26: 26px;
$fs-28: 28px;
$fs-30: 30px;
$fs-32: 32px;
$fs-34: 34px;
$fs-36: 36px;
$fs-38: 38px;
$fs-40: 40px;

$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-medium: 600;
$fw-bold: 700;

// Theme spacing
$sp-1: 4px;
$sp-2: 8px;
$sp-3: 12px;
$sp-4: 16px;
$sp-5: 20px;
$sp-6: 24px;
$sp-7: 28px;
$sp-8: 32px;
$sp-9: 36px;
$sp-10: 40px;

$xl: 1440px;
$lg: 1199px;
$md: 991px;
$sm: 767px;
$xs: 575px;

@font-face {
  font-family: 'SFProDisplaySemiBold';
  src: url('../../Assets/Fonts/SF_PRO_Display/SF-Pro-Display-Semibold.ttf');
}

@font-face {
  font-family: 'SFProDisplayRegular';
  src: url('../../Assets/Fonts/SF_PRO_Display/SF-Pro-Display-Regular.ttf');
}

@font-face {
  font-family: 'SFProDisplayMedium';
  src: url('../../Assets/Fonts/SF_PRO_Display/SF-Pro-Display-Medium.ttf');
}

@font-face {
  font-family: 'SFUiDisplaySemiBold';
  src: url('../../Assets/Fonts/SF_Ui_Display/SF-UI-Display-Semibold.ttf');
}

@font-face {
  font-family: 'SFUiDisplayMedium';
  src: url('../../Assets/Fonts/SF_Ui_Display/SF-UI-Display-Medium.ttf');
}

@font-face {
  font-family: 'SFProDisplayBold';
  src: url('../../Assets/Fonts/SF_PRO_Display/SF-Pro-Display-Bold.ttf');
}

$SfPro-semibold: 'SFProDisplaySemiBold';
$SfPro-regular: 'SFProDisplayRegular';
$SfPro-medium: 'SFProDisplayMedium';
$SfPro-bold: 'SFProDisplayBold';
$SfUi-semibold: 'SFUiDisplaySemiBold';
$SfUi-medium: 'SFUiDisplayMedium';

$regular-font: $SfPro-regular;
$medium-font: $SfPro-medium;
$semibold-font: $SfUi-semibold;
