@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.WelcomeBox {
  min-height: 100vh;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 60px 0;
  background-color: $themeMain;
}

.GridBox {
  width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #fff;
  border-radius: 30px;
  // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.Title {
  color: #190482;
  margin: 15px;
  font-family: $SfPro-regular;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}

.SubTitle {
  color: #071437;
  margin: 12px;
  font-family: $SfPro-regular;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.HeadTxt {
  color: #78829d;
  margin: 12px 0px;
  font-family: $SfPro-regular;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}

.HeadSubTxt {
  color: $black;
  margin: 12px 0px;
  font-family: $SfPro-regular;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  padding: 0px 70px;
  text-wrap: pretty;
}

.LineTxt {
  color: #78829d;
  font-family: $SfPro-regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
