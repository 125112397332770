@import '../Variable/index.scss';

body {
  user-select: none;
}
// Define a mixin for flexbox layout
@mixin flex($direction: row, $align: center, $justify: center) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

// Define a mixin for border-radius
@mixin border-radius($radius: 10px) {
  border-radius: $radius;
}

// Define a mixin for positioning
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin relative-position {
  position: relative;
}

@mixin fit-content {
  width: fit-content;
}

@mixin flex-start {
  align-items: flex-start;
}

@mixin flex-end {
  align-items: flex-end;
}

@mixin flex-center {
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-gap($gap) {
  gap: $gap;
}

@mixin flex-auto {
  flex: 0 0 auto;
}

@mixin flex-grow {
  flex: 1 1;
  flex-grow: 1;
}

@mixin stretch-self {
  align-self: stretch;
}

@mixin stretch-width {
  width: 100%;
}

%field {
  @include flex(_, flex-start, center);
  @include border-radius();
  align-self: stretch;
  font-weight: 400;
  border: none;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0;
  position: relative;
  width: 100%;
  input {
    background-color: $gray;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
