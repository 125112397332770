@import 'src/Styles/Mixin/index.scss';
@import 'src/Styles/Variable/index.scss';

// Define variables
$regular-ff: 'SF Pro Display-Regular', Helvetica;
$bold-ff: 'SF Pro DisplayBold', Helvetica;

.ApplicationBox {
  display: block;
  align-items: flex-start;
  justify-content: space-between;
  height: 436px;
  width: 100%;
  border-radius: 20px;
  background-color: $white;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  border: 1px solid #f1f1f4;
}

.ApplicationBoxTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $fs-20;
  font-family: $SfUi-semibold;
  margin-bottom: 30px;
  font-weight: $fw-medium;
}

.ApplicationButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ApplicationChartBox {
  width: 100%;
  height: 350px;
  padding: 5px;
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.ApplicationChartLegend {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .LegendTitle {
    display: flex;
    align-items: center;
    font-size: $fs-18;
    font-family: $SfUi-medium;
    gap: 15px;
  }

  .LegendSpan {
    width: 15px;
    height: 10px;
    border-radius: 5px;
  }
}
