@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

// Define mixins
@mixin mid-text($size, $weight, $height) {
  font-family: $SfPro-regular;
  font-size: $size;
  font-weight: $weight;
  letter-spacing: 0;
  line-height: $height;
}

// Use variables and mixins
.ContentHeadActionText {
  color: $darker-gray;
  @include mid-text(16px, 400, 12px);
  @include relative-position;
  @include fit-content;
}

.SubGrid {
  @include flex-row;
  @include flex-gap(10px);
  @include stretch-width;
}

.MainGrid {
  display: table;
  width: 100%;
}

.TableCell {
  color: inherit;
  vertical-align: middle;
  border-bottom: 1px dashed $dark-gray;
}

.TableCellBox {
  @include flex-row;
  @include flex-gap(10px);
}

.ContentBox {
  @include flex-start;
  @include flex-column;
  @include flex-grow;
  @include flex-gap(10px);
  @include relative-position;
}

.ContentHeadBox {
  @include flex-start;
  @include flex-row;
  @include stretch-self;
  justify-content: space-between;
  @include relative-position;
}

.ContentHeadNameText {
  font-family: $SfPro-bold;
  color: $themeMain;
  font-size: $fs-20;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 30px;
}

.ContentHeadActionBox {
  @include flex-center;
  @include flex-row;
  background-color: $gray;
  border-radius: 8px;
  @include flex-auto;
  @include flex-gap(10px);
  width: fit-content;
  padding: 8px 15px;
  @include relative-position;
}

.ContentHeadSubText {
  @include stretch-self;
  color: $darker-gray;
  font-family: $SfPro-regular;
  font-size: $fs-18;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  @include relative-position;
}

.ContentTypeBox {
  @include flex-center;
  @include flex-row;
  background-color: $white;
  border: 1px solid;
  border-color: $dark-gray;
  border-radius: 100px;
  max-width: fit-content;
  @include flex-auto;
  @include flex-gap(12px);
  justify-content: center;
  padding: 6px 15px;
  @include relative-position;
}

.ContentContactBox {
  @include flex-start;
  @include flex-row;
  @include stretch-self;
  @include flex-gap(30px);
  @include relative-position;
  @include stretch-width;
}

.ContentText {
  color: $darker-gray;
  @include mid-text(16px, 400, 12px);
  @include relative-position;
  @include fit-content;
  margin-top: -1px;
  white-space: nowrap;
}

.ContentContactSubBox {
  @include flex-center;
  @include flex-row;
  @include flex-auto;
  @include flex-gap(6px);
  @include relative-position;
}

.switch {
  flex: 0 0 auto;
  position: relative;
}

.popover {
  @include flex-center;
  padding: $sp-2;
}

.popoverText {
  cursor: pointer;
}

.popoverTextRed {
  cursor: pointer;
  color: $red;
}

.DialogProps {
  border-radius: 20px;
  height: fit-content;
  padding: 0;
  overflow-x: clip;
}

.MainBox {
  height: auto;
  width: 900px;
  border-radius: 20px;
  background-color: $white;
  overflow-x: hidden;
}

.DialogTitle {
  border-bottom: 1px solid $themeMain;
}

.TitileBox {
  padding: 20px;

  img {
    cursor: pointer;
  }
}

.titleText {
  justify-content: space-between;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $blueMain;
  display: flex;
}

.DialogContentBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 30px;
  padding: 30px 100px;
  gap: 20px;
}

.ConfirmButton {
  border-radius: 15px;
  background-color: $themeMain;
  color: $white;
  padding: 0px 30px;
  margin: 0;
}

.ConfirmButton:hover {
  color: $themeMain;
}

.flexStartBox {
  @include flex(column, flex-start, center);
  @include border-radius();
  align-self: stretch;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.flexSpaceBox {
  align-self: stretch;
  @include flex(row, '', space-between);
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.textFieldWrapper {
  display: flex;
  color: $black;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  .FF002E {
    color: $red;
  }
}

.field {
  @extend %field;
}

.fullWidth {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.noGap {
  gap: 0;
  align-self: stretch;
  @include flex(row, center, space-between);
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.halfField {
  width: calc(50% - 5px);
  background-color: #f2f4f8;
  @extend %field;
}

.inField {
  padding: 5px;
  background-color: #f2f4f8;
  @extend %field;
}

.toggleWrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.toggleText {
  color: $themeMain;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.switch {
  flex: 0 0 auto;
  position: relative;
}
