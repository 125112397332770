@import '/src/Styles/Variable/index.scss';
@import '/src/Styles/Mixin/index.scss';

.center {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}

.containerHeight {
  height: calc(100vh);
  position: fixed;
  background-size: contain;
  justify-content: flex-end;
}

.textAlignEnd {
  text-align: end;
}

.secondContainerHeight {
  height: calc(100vh - 20px);
  overflow: auto;
}

.wBox {
  width: 460px;
}

.bgCmn1 {
   background-color: $white ;
}


.br20 {
  border-radius: 10px;
}

@media (max-width: 1100px) {
  .containerHeight {
    position: absolute;
  }
}

.p10 {
  padding: 10px;
}


.signin {
  @include flex(row, flex-end);
  flex: 0 0 auto;
  margin-top: 40px;
  gap: 6px;
  cursor: pointer;
}

.button {
  all: unset;
  @include flex();
  @include border-radius(15px);
  box-sizing: border-box;
  align-self: stretch;
  background-color: $themeMain;
  flex: 0 0 auto;
  gap: 10px;
  padding: 13px 15px;
  position: relative;
  width: 100%;
  color: $white;

  &:hover {
    cursor: pointer;
    color: $white;
     background-color: $themeMain;
  }
}

.subTitle {
  color:#99A1B7
}

.textFieldWrapper {
   color: $themeMain;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.mediumText,
.textWrapper8 {
  color: #99A1B7;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mediumText {
  color: $white;
}

.mediumText,
.textWrapper8 {
  margin-top: -1.5px;
}




