@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.ContactBox {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ContactnGrid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactMainBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.ContactTitle {
  font-family: $SfPro-regular;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #190482;
}

.ContactMainLine {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 2px;
  background-color: #7752fe;
}

.ContactSubBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 2;
}

.Round {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #7752fe;
  box-sizing: border-box;
}

.ContactDotCircleRound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #7752fe;
  box-sizing: border-box;
}

.ContactDotCircle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #7752fe;
}

.ContactFillCircle {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #7752fe;
  display: flex;
  align-items: center;
  justify-content: center;
}
