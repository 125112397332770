@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.header-container {
  height: 90px;
  justify-content: space-between;
  padding: 20px 40px 20px 30px;
  border-bottom: 1.5px solid #dbdfe9;
}
.sub-header-container {
  width: 164px;
  height: 48px;
  margin-left: 10px;
}
.d-flex {
  display: flex;
}

.img-hw-24 {
  width: 24px;
  height: 24px;
}

.img-m-10 {
  margin: 10px;
}

.mr-25 {
  margin-right: 25px;
}

.img-box {
  border: 2px solid $dark-gray;
  padding: 10px;
  border-radius: 12px;
  height: 50px;
  width: 200px;
  display: flex;
  align-items: center;
}

.img-style-1 {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  // filter: grayscale(1);
}

.SfUi-semiBold {
  font-family: $SfUi-semibold;
  font-size: $fs-18;
  font-weight: $fw-medium;
  text-wrap: nowrap;
}

.SfUi-semi {
  font-family: $SfUi-medium;
  font-size: $fs-18;
  font-weight: $fw-light;
  text-wrap: nowrap;
  color: $darker-gray;
}

.SfUi-semi-red {
  font-family: $SfUi-medium;
  font-size: $fs-18;
  font-weight: $fw-light;
  text-wrap: nowrap;
  color: $red;
}

.text-cmn-3 {
  font-family: $SfPro-medium;
  font-size: $fs-18;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #99a1b7;
  text-wrap: nowrap;
}

.msg-box {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
  margin-top: 6px;
}

.img-style-2 {
  width: 10px;
  height: 10px;
  margin: auto 0px auto 10px;
}

.imgStyleRotate {
  width: 10px;
  height: 10px;
  margin: auto 0px auto 10px;
  rotate: 180deg;
}

.text-cmn-2 {
  color: $themeMain !important;
}

.profileBox {
  background-color: $white;
  width: 200px;
  padding: 10px 20px;
  border-radius: 12px;
}
