@import 'src/Styles/Mixin/index.scss';
@import 'src/Styles/Variable/index.scss';

.overviewBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 0px;
  // margin: 0px 30px;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  // background-color: white;
}

.overviewBoxTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $fs-20;
  font-family: $SfUi-semibold;
  margin-bottom: 30px;
  font-weight: $fw-medium;
}

.overviewCardCount {
  font-size: $fs-36;
  font-weight: $fw-medium;
  font-family: $SfUi-medium;
}
.overviewCardTxt {
  font-size: $fs-18;
  font-weight: $fw-medium;
  font-family: $SfUi-medium;
  color: $dark-gray;
}

.overviewCard {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}
.overviewCardContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  img {
    filter: sepia(10) hue-rotate(200deg) saturate(100);
  }
}

.overviewGridBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px;
  width: 23.5%;
  border: 1px solid $gray;
  border-radius: 20px;
  background-color: $lightMain;
}
