@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.customTooltip {
  background-color: #fffd;
  backdrop-filter: blur(1px);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.myHeader {
  padding: 10px;
}

.myDivider {
  border: 1px solid #d2d1d1;
  color: #a8a7a7;
}

.myDetails {
  display: inline-flex;
  padding: 6px;
  gap: 7px;
  align-items: center;
}
.myIcon {
  width: 15px;
  height: 10px;
  background-color: $themeMain;
  border-radius: 5px;
}

.RecurringRevenueBox {
  display: block;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background-color: $white;
  padding: 30px;
  border: 1px solid #f1f1f4;
}

.RecurringRevenueBoxTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $fs-20;
  font-family: $SfUi-semibold;
  margin-bottom: 20px;
}

.RecurringRevenueBoxText {
  font-weight: 600;
  line-height: 30px;
  font-size: $fs-20;
  font-family: $SfUi-semibold;
}

.RecurringRevenueBoxDate {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-16;
  width: max-content;
  font-family: $SfPro-regular;
  color: $darker-gray;
  background-color: #e5e5e5;
  border-radius: 6px;
  margin: 5px;
  padding: 2px 8px;
  cursor: pointer;
}

.RecurringRevenueDate {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-16;
  width: max-content;
  font-family: $SfPro-regular;
  color: $darker-gray;
  // background-color: #e5e5e5;
  // border-radius: 6px;
  // margin: 5px;
  // padding: 6px 10px;
}

.RecurringRevenueTxtAMain {
  display: flex;
  align-items: center;
  font-size: $fs-34;
  font-family: $SfPro-medium;
  margin-bottom: 2px;
}

.RecurringRevenueTxtSub {
  display: flex;
  align-items: center;
  font-size: $fs-14;
  font-family: $SfPro-medium;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px 10px;
}

.ApplicationChartBox {
  width: 100%;
  height: 350px;
  padding: 5px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.chartGrid {
  width: 100%;
  flex-grow: 1;
  flex-basis: 600px;
}

.theBtn {
  height: 32px;
  border-radius: 12px;
  border: 1px dashed #dbdfe9;
  background-color: $white;
  font-family: $SfPro-medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: $subGrayText;
}
.theActiveBtn {
  height: 32px;
  border-radius: 12px;
  border: 1px dashed $themeMain;
  background-color: #f1eeff;
  font-family: $SfPro-medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: $themeMain;
}

.DateRangePicker {
  // width: 50%;
  // border: 1px solid #0e0e0e;
  // border-radius: 10px;
}
