@import '/src/Styles/Variable/index.scss';
@import '/src/Styles/Mixin/index.scss';

.main {
  padding: 20px;
}

.HeadText {
  color: $themeMain;
  font-family: $SfPro-semibold;
  font-size: $fs-24;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.SubText {
  color: #4a5568;
  font-family: $SfPro-regular;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-align: center;
}

.textFieldWrapper {
  display: flex;
  color: $black;

  .FF002E {
    color: $red;
  }
}

.flexStartBox {
  @include flex(column, flex-start, center);
  @include border-radius();
  align-self: stretch;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.field {
  @extend %field;
}

.GridList {
  overflow-y: auto;
  height: calc(100vh - 200px);
}

.AddBox {
  position: sticky;
  top: 0;
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.gridContainer {
  // @include flex(row, center, center);
  padding-right: 20px;
  overflow: scroll;
}

.container {
  @include flex(column, center, center);
  flex: 0 0 auto;
  width: 100%;
  gap: 10px;
  padding: 20px 0;
  position: sticky;
  overflow: hidden;
}

.title {
  @include flex(row, center, center);
  align-self: flex-start;
  color: $themeMain;
  font-family: $SfUi-semibold;
  font-size: $fs-20;
  font-weight: $fw-semi-medium;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.notification {
  @include flex(row, center, center);
  width: 100%;
  gap: 10px;
  padding: 20px;
  flex: 0 0 auto;
  background-color: $white;
  border-radius: 20px;
  position: relative;
}

.createNotification {
  @include flex(column, flex-start, center);
  width: 100%;
  gap: 20px;
  padding: 30px;
  flex: 0 0 auto;
  background-color: $white;
  border-radius: 20px;
  position: relative;
}

.icon {
  align-items: flex-start;
  background-color: #d9d9d9;
  border-radius: 12px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 12px;
  position: relative;
}

.notiContent {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  position: relative;
}

.p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: $themeMain;
  display: -webkit-box;
  flex: 1;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.date {
  color: $darker-gray;
  font-family: $SfPro-regular;
  font-weight: $fw-medium;

  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.subTitle {
  align-self: stretch;
  color: $darker-gray;
  font-family: $SfPro-regular;
  font-weight: $fw-medium;

  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}

.head {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.button {
  @include flex();
  @include border-radius(12px);
  box-sizing: border-box;
  align-self: flex-start;
  background-color: $themeMain;
  flex: 0 0 auto;
  gap: 10px;
  margin: 0;
  height: auto;
  padding: 8px 14px;
  position: relative;
  width: fit-content;
  color: $white;

  &:hover {
    cursor: pointer;
    color: $themeMain;
    box-shadow:
      1px 1px 2px $themeMain,
      inset 1px 1px 3px $themeMain;
  }
}
