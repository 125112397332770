@import '/src/Styles/Variable/index.scss';

.center {
justify-content: center;
align-items: center;
display: flex;
color: $themeMain;
}

.footerElm{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.alignItemEnd {
  align-self: flex-end;
}
.contentStart {
  justify-content: flex-start;
  align-items: center;
display: flex;
color: $themeMain;
}
.wBox {
  width: 460px;
}
.fwBold {
  font-weight: $fw-bold;
}