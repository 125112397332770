.mainLegal {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding: 30px;
}

.mainLegal .frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.mainLegal .div-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.mainLegal .textWrapper {
  position: relative;
  width: fit-content;
  text-wrap: pretty;
}
.mainLegal .text-wrapper-2 {
  position: relative;
  width: fit-content;
  text-wrap: pretty;
}

.mainLegal .element {
  align-self: stretch;
  flex-direction: row;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
}

.mainLegal .radio-selection-boxes {
  margin: 0;
  flex: 1;
  display: flex;
  flex-grow: 1;
  width: 100vh;
  padding: 20px;
  border-radius: 20px;
  border: 1px dashed blue;
  .radio-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: unset;
    // padding: 20px;
  }
}

.mainLegal .details-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
  margin-top: 60px;
}

.mainLegal .text-wrapper-3 {
  color: var(--neutralgray-1000);
  font-family: var(--text-medium-semibold-font-family);
  font-size: var(--text-medium-semibold-font-size);
  font-style: var(--text-medium-semibold-font-style);
  font-weight: var(--text-medium-semibold-font-weight);
  letter-spacing: var(--text-medium-semibold-letter-spacing);
  line-height: var(--text-medium-semibold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mainLegal .frame-2 {
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  position: relative;
  width: 100%;
}

.mainLegal .fields {
  align-items: flex-end;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 25px;
  justify-content: center;
  position: relative;
}

.mainLegal .field {
  align-items: center;
  align-self: stretch;
  background-color: var(--background-border100);
  border: 1.5px solid;
  border-color: var(--background-border300);
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 13px 15px;
  position: relative;
  width: 100%;
}

.mainLegal .frame-3 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.mainLegal .text-wrapper-4 {
  color: var(--neutralgray-700);
  font-family: var(--text-regular-regular-font-family);
  font-size: var(--text-regular-regular-font-size);
  font-style: var(--text-regular-regular-font-style);
  font-weight: var(--text-regular-regular-font-weight);
  letter-spacing: var(--text-regular-regular-letter-spacing);
  line-height: var(--text-regular-regular-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mainLegal .chevron-down {
  height: 16px;
  position: relative;
  width: 16px;
}

.mainLegal .frame-4 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 125px;
  position: relative;
}

.mainLegal .fields-2 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.mainLegal .frame-5 {
  align-items: flex-start;
  align-self: stretch;
  // background-color: #ffffff;
  border: 1px solid;
  border-color: #e1e4eb;
  border-radius: 20px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  position: relative;
  width: 100%;
}

.mainLegal .frame-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.mainLegal .line-duotone {
  height: 24px;
  position: relative;
  width: 24px;
}

.mainLegal .card-add-instance {
  align-self: stretch;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: #f1eeff;
  border-radius: 20px;
}

.mainLegal .card-save-instance {
  align-self: stretch;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  .card-back {
    padding: 5px 60px;
    margin: 0;
    background-color: gray;
  }
  .card-save {
    padding: 5px 60px;
    margin: 0;
    background-color: #7752fe;
  }
}

.addNamesButton {
  padding: 5px 30px;
  background-color: #7752fe;
  margin: 0;
}

.mainLegal .action-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--neutralgray-100);
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 0px 30px;
  position: relative;
  width: 100%;
}

.mainLegal .add {
  align-items: center;
  background-color: var(--neutralgray-300);
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 8px 25px;
  position: relative;
  width: 160px;
}

.mainLegal .text-wrapper-5 {
  color: var(--neutralgray-900);
  font-family: var(--text-regular-medium-font-family);
  font-size: var(--text-regular-medium-font-size);
  font-style: var(--text-regular-medium-font-style);
  font-weight: var(--text-regular-medium-font-weight);
  letter-spacing: var(--text-regular-medium-letter-spacing);
  line-height: var(--text-regular-medium-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mainLegal .div-wrapper {
  align-items: center;
  background-color: var(--primary-shades-tints100);
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 8px 20px;
  position: relative;
  width: 160px;
}

.mainLegal .text-wrapper-6 {
  color: #ffffff;
  font-family: var(--text-regular-medium-font-family);
  font-size: var(--text-regular-medium-font-size);
  font-style: var(--text-regular-medium-font-style);
  font-weight: var(--text-regular-medium-font-weight);
  letter-spacing: var(--text-regular-medium-letter-spacing);
  line-height: var(--text-regular-medium-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
