@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.customTooltip {
  background-color: #fffd;
  backdrop-filter: blur(1px);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.myHeader {
  padding: 10px;
}

// .myDivider {
//   border: 1px solid #808080;
//   color: #808080;
// }

.myDetails {
  display: inline-flex;
  padding: 2px 10px 2px 10px;
  font-size: $fs-14;
  float: inline-start;
  align-items: left;
  justify-content: left;
}
.myIcon {
  width: 15px;
  border-radius: 5px;
}

.maintxt {
  font-size: $fs-16;
  font-weight: $fw-medium;
  font-family: $SfPro-bold;
}

.myContainer {
  display: block;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
  height: 436px;
  width: 100%;
  border-radius: 20px;
  background-color: $white;
  border: 1px solid #f1f1f4;
}
.myHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $fs-20;
  font-family: $SfUi-semibold;
  margin-bottom: 20px;
}
.myDate {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-16;
  width: max-content;
  font-family: SFProDisplayRegular;
  color: $black; /* Use your COLORS.COLOR_BLUE value */
  background-color: #f4f4f4; /* Use your COLORS.COLOR_GRAY value */
  border-radius: 6px;
  margin-top: 1px;
  padding: 2px 6px;
}

.myIcon {
  margin-left: 4px;
}

.ApplicationChartBox {
  width: 100%;
  height: 350px;
  padding: 5px;
  // margin-top: 30px;
  display: flex;
  align-items: center;
}

.chartGrid {
  width: 100%;
  flex-grow: 1;
  flex-basis: 600px;
}

.RecurringRevenueBoxTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $fs-20;
  font-family: $SfUi-semibold;
  margin-bottom: 20px;
}

.RecurringRevenueBoxText {
  font-weight: 600;
  line-height: 30px;
  font-size: $fs-20;
  font-family: $SfUi-semibold;
}

.RecurringRevenueBoxDate {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-16;
  width: max-content;
  font-family: $SfPro-regular;
  color: $darker-gray;
  background-color: #e5e5e5;
  border-radius: 6px;
  margin: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.RecurringRevenueDate {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-16;
  width: max-content;
  font-family: $SfPro-regular;
  color: $darker-gray;
  // background-color: #e5e5e5;
  // border-radius: 6px;
  // margin: 5px;
  // padding: 6px 10px;
}
