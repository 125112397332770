@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.Main {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  position: relative;
}

.DialogProps {
  border-radius: 20px;
  height: fit-content;
  padding: 0;
  overflow-x: hidden;
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ListWrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  position: relative;
  width: 100%;
}

.ListGrid {
  align-items: flex-start;
  display: flex;
  flex: 1;
  gap: 20px 20px;
  position: relative;
  border-radius: 15px;
  flex-grow: 1;
  flex-wrap: wrap;
}

.Head {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 30px;
  position: relative;
  width: 100%;
}

.HeadText {
  color: $black;
  font-family: $SfPro-semibold;
  font-size: $fs-24;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.SubText {
  color: #4a5568;
  font-family: $SfPro-regular;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-align: center;
}

.MidText {
  color: #4a5568;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.Add {
  align-items: center;
  align-self: stretch;
  background-color: $black;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  padding: 8px 20px;
  margin: 0;
  height: auto;
}

.Right {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  height: 40px;
  position: relative;
}

.Left {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  position: relative;
}

.SearchBox {
  align-items: center;
  background-color: $white;
  border: 1px solid;
  border-color: $dark-gray;
  border-radius: 10px;
  display: flex;
  gap: 6px;
  padding: 8px 15px;
  position: relative;
  width: 300px;
}

.Action {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.SearchEm {
  background: transparent;
  border: none;
  border-bottom: none;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
}

.SearchEm > input {
  padding: 0;
}

.div {
  align-content: flex-start;
  flex-wrap: wrap;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.flexStartBox {
  @include flex(column, flex-start, center);
  border-radius: 10px;
  align-self: stretch;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.field {
  @include flex();
  border-radius: 10px;
  align-self: stretch;
  background-color: #edeff4;
  border: none;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0;
  position: relative;
  width: 100%;
}

.flexSpaceBox {
  padding: 10px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .flexSpaceBox {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}

.column {
  display: grid;
  height: 100%;
  line-height: normal;
}

.whiteContainer {
  border-radius: 20px;
  background-color: $white;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-weight: $fw-medium;
  color: $black;
  font-weight: $fw-regular;
  width: 100%;
  padding: 30px;
}

.titleText {
  font-size: $fs-24;
  font-family: $SfPro-semibold;
  color: #190482;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Avatar {
  height: 36px;
  width: 36px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.MainBox {
  height: auto;
  width: 900px;
  border-radius: 20px;
  background-color: $white;
}

.DialogTitle {
  border-bottom: 1px solid $black;
}
.TitileBox {
  padding: 20px;
  img {
    cursor: pointer;
  }
}

.DialogContentBox {
  display: block;
  align-items: center;
  justify-content: flex-start;
  margin: 30px 100px;
  gap: 10px;
}

.Checkbox {
  display: flex;
  gap: 30px;
  column-gap: 30px;
  background-color: $gray;
  padding: 10px 20px;
  border: 1px solid $dark-gray;
  border-radius: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.label {
  margin: 10px 0px;
  color: $black;
  // background-color: $white-variant-2;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-bold;
}

.AnsTxt {
  input {
    color: #8690ab;
    background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
  textarea {
    color: #8690ab;
    // background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
  div[role='combobox'] {
    color: #8690ab;
    background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
}

.CheckText {
  font-size: $fs-16;
  font-weight: $fw-regular;
  font-family: $SfPro-medium;
}

.CheckGrid {
  display: flex;
  align-items: center;
  width: 30%;
}

.AddButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Button {
  border-radius: 15px;
  background-color: $themeMain;
  color: $white;
  padding: 0px 30px;
  width: 160px;
  margin-bottom: 0;
}
.MuiDialogContent-root {
  -webkit-overflow-scrolling: initial;
  overflow-y: initial;
}
