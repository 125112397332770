@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

// Define mixins
@mixin mid-text($size, $weight, $height) {
  font-family: $SfPro-regular;
  font-size: $size;
  font-weight: $weight;
  letter-spacing: 0;
  line-height: $height;
}

// Use variables and mixins
.ContentHeadActionText {
  color: $darker-gray;
  @include mid-text(16px, 400, 12px);
  @include relative-position;
  @include fit-content;
}

.SubGrid {
  @include flex-row;
  @include flex-gap(10px);
  @include stretch-width;
}

.MainGrid {
  display: table;
  width: 100%;
}

.TableCell {
  vertical-align: middle;
  border-bottom: 1px dashed $dark-gray;
}

.TblHeading {
  color: #252f4a;
  font-family: $SfPro-regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px dashed $dark-gray;
}

.TableCellBox {
  @include flex-row;
  @include flex-gap(10px);
}

.ContentBox {
  @include flex-start;
  @include flex-column;
  @include flex-grow;
  @include flex-gap(10px);
  @include relative-position;
}

.ContentHeadBox {
  @include flex-start;
  @include flex-row;
  @include stretch-self;
  justify-content: space-between;
  @include relative-position;
}

.ContentHeadNameText {
  font-family: $SfPro-regular;
  color: #252f4a;
  font-size: $fs-16;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 30px;
}

.ContentHeadSubTxt {
  color: #78829d;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  // width: -webkit-fill-available;
  text-wrap: balance;
  font-size: $fs-16;
  text-wrap: nowrap;
  white-space: nowrap;
}

.ContentHeadActionBox {
  @include flex-center;
  @include flex-row;
  background-color: $gray;
  border-radius: 8px;
  @include flex-auto;
  @include flex-gap(10px);
  width: fit-content;
  padding: 8px 15px;
  @include relative-position;
}

.ContentHeadSubText {
  @include stretch-self;
  color: $darker-gray;
  font-family: $SfPro-regular;
  font-size: $fs-18;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  @include relative-position;
}

.ContentTypeBox {
  @include flex-center;
  @include flex-row;
  background-color: $white;
  border: 1px solid;
  border-color: $dark-gray;
  border-radius: 100px;
  max-width: fit-content;
  @include flex-auto;
  @include flex-gap(12px);
  justify-content: center;
  padding: 6px 15px;
  @include relative-position;
}

.ContentContactBox {
  @include flex-start;
  @include flex-row;
  @include stretch-self;
  @include flex-gap(30px);
  @include relative-position;
  @include stretch-width;
}

.ContentText {
  color: $darker-gray;
  @include mid-text(16px, 400, 12px);
  @include relative-position;
  @include fit-content;
  margin-top: -1px;
  white-space: nowrap;
}

.ContentContactSubBox {
  @include flex-center;
  @include flex-row;
  @include flex-auto;
  @include flex-gap(6px);
  @include relative-position;
}

.ContentHead {
  color: $subGrayText;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  // width: -webkit-fill-available;
  text-wrap: balance;
  font-size: $fs-16;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: #f1f1f4;
  text-wrap: nowrap;
  white-space: nowrap;
}

.txtBox {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
}
