@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

@mixin font($size, $weight, $height) {
  font-family: $SfPro-regular;
  font-size: $size;
  font-weight: $weight;
  letter-spacing: 0;
  line-height: $height;
}

.EditDetails {
  padding: 30px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.titleText {
  color: $black;
  font-family: $SfPro-semibold;
  @include font(20px, 600, 30px);
}

.EditContactImage {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.IconEditWrapper {
  align-items: center;
  display: flex;
  left: 60px;
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 1;

  .EditIcon {
    cursor: pointer;
  }
}

.inputFile {
  cursor: pointer;
  display: flex;
  position: absolute;
  height: 32px;
  width: 32px;
  opacity: 0;
}
::-webkit-file-upload-button {
  display: none;
}

.IconDeleteWrapper {
  left: 60px;
  position: absolute;
  top: 80px;
  cursor: pointer;
}

.Description {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.DescriptionP {
  color: $blue;
  font-family: $SfPro-regular;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.Action {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  width: 100%;
}

.ActionButton {
  all: unset;
  align-items: center;
  background-color: $dark-gray;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 13px 15px;
  position: relative;
  width: 160px;
}

.TextWrapper3 {
  color: $themeMain;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  opacity: 0.7;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.DivWrapper {
  all: unset;
  align-items: center;
  background-color: $themeMain;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 13px 15px;
  position: relative;
  width: 160px;
}

.TextWrapper4 {
  color: $white;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.flexSpaceBox {
  align-self: stretch;
  @include flex(row, '', space-between);
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.fullWidth {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.halfWidth {
  width: calc(50% - 5px);
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.textFieldWrapper {
  display: flex;
  color: $black;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  .FF002E {
    color: $red;
  }
}

.field {
  font-size: 14px;
  @extend %field;
}

.inField {
  font-size: 14px;
  padding: 7px;
  background-color: $gray;
  @extend %field;
}

.smallGrayBox {
  display: flex;
  background-color: $lightMain;
  border-radius: 30px;
  padding: 2px 10px;
}

.dottedLine {
  width: 100%;
  height: 1px;
  border: 1px dashed #c4cada;
}

.toggleWrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.toggleText {
  color: $themeMain;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
