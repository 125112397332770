@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.MainBox {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  margin: 30px;
}

.title {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 100%;
}

.main {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.textWrapper {
  color: themeMain;
  font-family: $SfPro-semibold;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
}
.SubTitle {
  color: $black;
  font-family: $SfPro-semibold;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
}

.button {
  all: unset;
  align-items: flex-start;
  background-color: #F9F9F9;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 6px 15px;
  position: relative;
}

.div {
  color: #6f737a;
  font-family: 'SF Pro Display-Regular', Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
  padding: 4px 10px;
  border-radius: 8px;
}

.dottedLine {
  align-self: stretch;
  // height: 1px;
  border: 1px solid #e1e4eb;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.div2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.element {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
  width: 100%;
}

.question {
  align-items: flex-start;
  display: flex;
  gap: 25px;
  position: relative;
  width: 260px;
}

.p {
  color: #9d9fa4;
  font-family: 'SF Pro Display-Medium', Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
  text-wrap: balance;
  font-size: 16px;
}

.ans {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 25px;
  position: relative;
  justify-content: space-between;
}

.textWrapperAns {
  color: themeMain;
  font-family: $SfPro-medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
  text-wrap: balance;
  font-size: 16px;
}

.textWrapper5 {
  color: #f40000;
  font-family: 'SF Pro Display-Medium', Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
  text-wrap: balance;
  font-size: 16px;
}

.textWrapper4 {
  color: themeMain;
  font-family: 'SF Pro Display-Medium', Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: fit-content;
  text-wrap: balance;
  font-size: 16px;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: $dark-gray;
}

.element2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
  width: 100%;
}

.textWrapper3 {
  color: #9d9fa4;
  flex: 1;
  font-family: 'SF Pro Display-Medium', Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

// Current and Past Employer
.card {
  align-items: flex-start;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  position: relative;
  margin: 10px 0px;
  width: -webkit-fill-available;
}

.cardDiv {
  align-items: flex-start;
  // backgroundColor: #ffffff;
  border: 2px dashed;
  border-color: #a4a4a7;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;
  padding: 30px;
  position: relative;
  width: -webkit-fill-available;
}

.cardDivBox {
  align-items: flex-start;
  // backgroundColor: #ffffff;
  border: 1px solid;
  border-color: #a4a4a7;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;
  padding: 30px;
  position: relative;
}

.cardFrame {
  align-items: flex-start;
  // align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  position: relative;
  // width: 100%;
}

.cardElement {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.cardQuestion {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 25px;
  position: relative;
}

.cardTextWrapper {
  color: #9d9fa4;
  flex: 1;
  font-family: 'SF Pro Display-Medium', Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.cardDivWrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 25px;
  position: relative;
  width: 100%;
}

.cardTextWrapper2 {
  color: themeMain;
  flex: 1;
  font-family: 'SF Pro Display-Medium', Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

// .cardFrame2 {
//   align-items: flex-start;
//   align-self: stretch;
//   display: flex;
//   gap: 30px;
//   position: relative;
//   width: 90%;
// }

// .cardElement2 {
//   align-items: flex-start;
//   display: flex;
//   flex: 1;
//   flex-direction: column;
//   flex-grow: 1;
//   position: relative;
// }

.cardAns {
flex-wrap: wrap;
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 25px;
  position: relative;
}

.cardTextWrapperAns {
  color: themeMain;
  font-family: 'SF Pro Display-Medium', Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.cardElement3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 44px;
  position: relative;
}

// .cardTextWrapper4 {
//   color: themeMain;
//   font-family: 'SF Pro Display-Medium', Helvetica;
//   font-size: 16px;
//   font-weight: 500;
//   letter-spacing: 0;
//   line-height: 20px;
//
//   position: relative;
//   white-space: nowrap;
//   width: fit-content;
// }
