@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.container{
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}

.dottedLine {
  align-self: stretch;
  // height: 1px;
  border: 1px solid #e1e4eb;
  object-fit: cover;
  position: relative;
  width: 100%;
}
.title {
  @include flex(row, flex-start, space-between);
}

.titleHeading {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 15px;
  position: relative;
  width: 100%;
}

.editButton {
  all: unset;
  align-items: center;
  background-color: #f2f4f8;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 6px 15px;
  position: relative;
}

.cardContent {
  display: flex;
  gap: 20px;
}

.cardDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cardBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex:  1 0 auto;
  border: 1px dashed rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  padding: 20px;
  gap: 20px;
  cursor: pointer;
  flex-direction: column;
}
