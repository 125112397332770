@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.Main {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  position: relative;
  padding: 30px;
  background-color: $gray;
}

.ListWrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  position: relative;
  width: 100%;
}

.ListGrid {
  align-items: flex-start;
  display: flex;
  flex: 1;
  gap: 20px 20px;
  position: relative;
  border-radius: 15px;
  flex-grow: 1;
  flex-wrap: wrap;
}

.Head {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 30px;
  position: relative;
  width: 100%;
}

.HeadText {
  color: #190482;
  font-family: $SfPro-semibold;
  font-size: $fs-24;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.SubText {
  color: #99a1b7;
  font-family: $SfPro-regular;
  font-weight: $fw-medium;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-align: center;
}

.MidText {
  color: #4a5568;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.MidText2 {
  color: $white;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.Add {
  align-items: center;
  align-self: stretch;
  background-color: $themeMain;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  padding: 8px 20px;
  margin: 0;
  height: auto;
}

.Right {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  height: 40px;
  position: relative;
}

.Left {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  position: relative;
}

.Box1 {
  align-items: center;
  background-color: #f1f1f4;
  border: 1px solid;
  border-color: $dark-gray;
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  padding: 8px 15px;
  position: relative;
}

.Main2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  justify-content: center;
  position: relative;
}

.SearchBox {
  align-items: center;
  background-color: $white;
  border: 1px solid;
  border-color: $dark-gray;
  border-radius: 10px;
  display: flex;
  gap: 6px;
  padding: 8px 15px;
  position: relative;
  width: 300px;
}

.Action {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.SearchEm {
  background: transparent;
  border: none;
  border-bottom: none;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
}

.SearchEm > input {
  padding: 0;
}

.div {
  align-content: flex-start;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.flexStartBox {
  width: 100%;
  // gap: 20px;
  display: flex;
}

.flexSpaceBox {
  padding: 10px;
  cursor: pointer;
  flex-wrap: wrap;
  border: 1px solid $dark-gray;
  border-radius: 15px;
}

.column {
  display: grid;
  border: 1px solid $dark-gray;
  border-radius: 15px;
}

.whiteContainer {
  border-radius: 20px;
  background-color: $white;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: $themeMain;
  width: 500px;
  padding: 20px;
  border: 1px solid $dark-gray;
  border-radius: 15px;
}

.div1 {
  font-size: $fs-20;
  font-family: $SfPro-semibold;
  font-weight: 600;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.div5 {
  font-size: $fs-18;
  font-family: $SfPro-medium;
  font-weight: 500;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.div7 {
  margin: 20px 1px;
  height: 1px;
  border: dashed 1px #c4cada;
}

.div6 {
  font-weight: $fw-medium;
  font-family: $SfPro-regular;
  color: #99a1b7;
  margin-top: 10px;
}

.div8 {
  font-size: $fs-16;
  font-family: $SfPro-medium;
}

.div9 {
  display: flex;
  gap: 20px;
  margin: 10px 1px;
}

.div10 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: $fw-medium;

  // text-wrap: nowrap;
}

.Avatar {
  height: 36px;
  width: 36px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
