@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.FontBold{
  font-weight: $fw-bold;
}

.alignItemCenter{
  align-items: center;
}
.EditBtnStyle {
  border-radius: 8px;
  height: 40px;
  background-color: $gray;
  font-size: $fs-16;
}
.grayFonts{
  color:$blue
}

.MediumBlackFonts{
  color:$black;
  font-weight: $fw-medium;
  font-family: $SfPro-medium;
}
.redFlagFonts{
  color:$dark-red;
  font-weight: $fw-medium;
  font-family: $SfPro-medium;
}

.BoxDevider {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
}