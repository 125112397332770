@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.icon {
  width: fit-content;
  position: fixed;
  right: 40px;
  bottom: 66px;
  z-index: 3000;
  background-color: #071437;
  &:hover {
    background-color: #777;
  }
}
.popper {
  background-color: $white;
  border-radius: 30px;
  height: 668px;
  width: 600px;
  box-shadow:
    0px 28px 250px 0px #441fcb14,
    0px 50px 113px 0px #441fcb0d,
    0px 253px 152px 0px #441fcb0d,
    0px 451px 180px 0px #441fcb03,
    0px 704px 197px 0px #441fcb00,
    0px 0px 0px 6px #ffffff40;
  z-index: 100;
}

.header {
  background-color: #071437;
  border-radius: 30px 30px 0 0;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
}
.headerButton {
  display: flex;
  gap: 20px;

  .button {
    color: #4b5675;
    border: 1px solid #4b5675;
    background-color: transparent;
    align-items: center;
    gap: 5px;
    border-radius: 30px;
  }
}

.headerText {
  flex-grow: 1;
  color: white;
}

.closeIcon {
  background: #252f4a;
  border-radius: 50%;
  color: white;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.body {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  height: 588px;
}
#el {
  display: none;
}

.chatBox {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ::-webkit-scrollbar {
    display: none;
  }

  .bot,
  .user,
  .audio {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    background-color: #f1f1f4;
    width: fit-content;
    border-radius: 16px;
    margin: 12px 0;
    font-family: $SfPro-medium;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    white-space: break-spaces;
  }
  .user,
  .bot {
    border-radius: 5px 25px 25px;
    // flex-direction: column;
    gap: 16px;
  }
  .user {
    border-radius: 25px 5px 25px 25px;
    direction: rtl;
    margin-left: 3rem;
  }
  .user,
  .audio {
    align-self: flex-end;
    background-color: #7752fe;
    color: #ffffff;
  }
  .audio {
    padding: 16px;
    gap: 16px;
    .audioContainer {
      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: 30px;
      padding: 8px;
      background-color: #5e39e4;
    }
  }
}

.chatContainer {
  overflow: auto;
  height: 480px;
  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  border-top: 1px solid gray;
  form {
    display: flex;
    gap: 10px;
    width: 100%;
  }
}
.input {
  fieldset,
  input,
  div {
    border: none;
    background-color: transparent !important;
    font-family: $SfPro-medium;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  flex-grow: 1;
  border: none;
  background-color: transparent;
  font-family: $SfPro-medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.sendButton {
  color: white;
  aspect-ratio: 1;
}

.recorder {
  display: flex;
  align-items: center;
}

.micbutton,
.micbuttonactive {
  background-color: transparent;
  border: none;
  height: 40px;
  width: 40px;
  padding: 0;
}

.micbuttonactive {
  background-color: #071437;
  border-radius: 50%;
}

.buttonContainer {
  display: flex;
  gap: 12px;
}

.button {
  background-color: white;
  border: none;
  min-width: 100px;
  border-radius: 10px;
  height: 36px;
  padding: 6px 16px;
}
