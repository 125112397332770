@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.title {
  @include flex(column, center, center);
  align-self: stretch;
  position: relative;
  width: 100%;
}

.textFieldWrapper {
  display: flex;
  color: $black;

  .FF002E {
    color: $red;
  }
}
.listBox {
    background-color: #FFFF;
    border-radius: 10px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    padding: 20px; // Adjust padding as needed
}
.listItem {
  &:hover {
        background-color: #F1F1F4;
        cursor: pointer;// Change background color on hover
    }
}
.phrase {
  align-self: stretch;
  color: $black;
  font-family: $SfUi-semibold;
  font-size: $fs-28;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: -1px;
  position: relative;
}

.subPhrase {
  align-self: stretch;
  color: $dark-gray;
  font-family: $SfPro-regular;
  font-size: $fs-18;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.form {
  @include flex(column, flex-end, center);
  align-self: stretch;
  position: relative;
  width: 100%;
  gap: 40px;
}

.fieldsWrapper {
  @include flex(column, flex-start, center);
  align-self: stretch;
  position: relative;
  width: 100%;
}

.fields {
  @include flex(column, flex-end, center);
  align-self: stretch;
  position: relative;
  width: 100%;
  gap: 20px;
}

.flexStartBox {
  @include flex(column, flex-start, center);
  @include border-radius();
  align-self: stretch;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}
.flexStartBoxRole {
 display: none;
  @include border-radius();
  align-self: stretch;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.field {
  @extend %field
}

.flexSpaceBox {
  align-self: stretch;
  @include flex(row, center, space-between);
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.fullWidth {
  width: 100%;
}

.validationBar {
  @include flex(row, center, flex-end);
  align-self: stretch;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

@mixin validationBarColor($color: $gray) {
  background-color: $color;
  @include border-radius(30px);
  flex: 1;
  flex-grow: 1;
  height: 6px;
  position: relative;
}

.validator {
  @include validationBarColor();

  &red {
    @include validationBarColor(red)
  }

  &orange {
    @include validationBarColor(orange)
  }

  &yellow {
    @include validationBarColor(yellow)
  }

  &green {
    @include validationBarColor(green)
  }
}

.useOrMore {
  color: $red;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.terms {
  @include flex(row, flex-start);
  align-self: stretch;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.byClickingContinue {
  color: $dark-gray;
  flex: 1;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
}

.span {
  color: $dark-gray;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
}

.underline {
  text-decoration: underline;
}

.button {
  all: unset;
  @include flex();
  @include border-radius(15px);
  box-sizing: border-box;
  align-self: stretch;
  background-color: $themeMain;
  flex: 0 0 auto;
  gap: 10px;
  padding: 13px 15px;
  position: relative;
  width: 100%;
  color: $white;

  &:hover {
    cursor: pointer;
    color: $themeMain;
    box-shadow: 1px 1px 2px $themeMain, inset 1px 1px 3px $themeMain;
  }
  &:disabled {
    background-color: $darker-gray;
    color:$gray;
    
  }
}

.mediumText {
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}