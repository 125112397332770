@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.Main {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  position: relative;
}
.Box1{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
}
.Box2{
  display: flex;
 justify-content: 'space-between';
  gap: 14px;
}
.AvatarBox {
   display: flex;
   justify-content: 'space-between';
    gap: 10px;
    text-align: center;
    align-items: center;
    cursor: pointer;
    padding:16px;
    border-radius: 10px;
}

.AvatarBoxSelected {
   display: flex;
   justify-content: 'space-between';
    gap: 10px;
    text-align: center;
    align-items: center;
    cursor: pointer;
    padding:16px;
    background-color: $dark-gray;
    border-radius: 10px;
}
.AvatarBox:hover {
   background-color: $dark-gray; /* Change this color to your desired hover background color */
}

.dottedLine {
  width: 100%;
  height: 1px;
  border: 1px dashed #c4cada;
}
.semiBoldText {
  color: $black;
  text-align: center;
  flex-grow: 1;
  font-size: $fs-16;
  font-weight: $fw-medium;
}
.search {
  border: 0;
  background-color: transparent;
  height: 40px;
  flex: 1;
  width: 100%;
  font-weight: $fw-medium;
  font-size: $fs-16;
  color: $blue;
}
.DialogProps {
  border-radius: 20px;
  width: 500px;
  height: fit-content;
  padding: 0;
  overflow-y: initial;
}

.HeadText {
  color: themeMain;
  font-family: $SfPro-semibold;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.SubText {
  color: #4a5568;
  font-family: $SfPro-regular;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-align: center;
}

.MidText {
  color: #4a5568;
  font-family: $SfPro-regular;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.Add {
  align-items: center;
  align-self: stretch;
  background-color: themeMain;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  padding: 8px 20px;
  margin: 0;
  height: auto;
}

.Action {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.div {
  align-content: flex-start;
  flex-wrap: wrap;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.flexStartBox {
  @include flex(column, flex-start, center);
  border-radius: 10px;
  align-self: stretch;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.field {
  @include flex();
  border-radius: 10px;
  align-self: stretch;
  background-color: rgb(255, 255, 255);
  border: none;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0;
  position: relative;
  width: 100%;
}

.flexSpaceBox {
  padding: 10px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .flexSpaceBox {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}

.column {
  display: grid;
  height: 100%;
  line-height: normal;
}

.whiteContainer {
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 14px;
  color: themeMain;
  font-weight: 400;
  width: 100%;
  font-style: italic;
  padding: 16px;
  gap:10px;
}

.titleText {
  font-size: 24px;
  font-family: $SfPro-semibold;
  color: $themeMain;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Avatar {
  height: 36px;
  width: 36px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.MainBox {
  height: auto;
  width: '700px';
  border-radius: '20px';
}

.DialogTitle {
  border-bottom: 1px solid $themeMain;
}
.TitileBox {
  padding: 20px;
  img {
    cursor: pointer;
  }
}

// .DialogContentBox {
//   display: grid;
//   align-items: center;
//   justify-content: center;
//   width: 643px;
// }

.DialogContentBox {
  display: block;
  align-items: center;
  justify-content: flex-start;
  // margin: 30px 100px;
  gap: 10px;
}

.AddButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  width: 100% ;
}


.Button {
  border-radius: 15px;
  background-color: $themeMain;
  color: $white;
  padding: 0px 30px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
}
.MuiDialogContent-root {
  -webkit-overflow-scrolling: initial;
  overflow-y: initial;
}
.textWrapper {
  align-self: stretch;
  color: themeMain;
  font-family: $SfPro-semibold;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-wrap: pretty;
  padding: 32px 10px 10px 10px;
}
