@import '/src/Styles/Variable/index.scss';
@import '/src/Styles/Mixin/index.scss';

.notificationPopup {
  position: relative;
  padding: 0 20px;
  border-radius: 16px;
  width: 600px;
  max-height: 30em;
}

.paper {
  background-color: $white;
  @include flex(column, center, center);
  position: relative;
  max-height: 30em;
  border-radius: 20px;
  width: fit-content;
  overflow: auto;
  padding-top: 10px;
}

.title {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 100%;
}

.textWrapper {
  color: $themeMain;
  font-family: $SfUi-semibold;
  font-size: $fs-20;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  padding: 20px 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.notificationCount {
  align-items: center;
  background-color: $themeMain;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 24px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 24px;
}

.div {
  color: $white;
  font-family: $SfUi-semibold;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: -2px;
  margin-left: -2.5px;
  margin-right: -2.5px;
  margin-top: -4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.divider {
  width: 100%;
  height: 1px;
  color: $darker-gray;
  border-top: 1px dashed;
}

.buttonWrapper {
  @include flex(row, center, center);
  flex: 0 0 auto;
  gap: 10px;
  padding: 20px;
  position: relative;
  width: 100%;
}

.button {
  @include flex();
  @include border-radius(15px);
  box-sizing: border-box;
  background-color: $themeMain;
  flex: 0 0 auto;
  gap: 10px;
  padding: 13px 15px;
  position: relative;
  width: fit-content;
  color: $white;

  &:hover {
    cursor: pointer;
    color: $themeMain;
    box-shadow:
      1px 1px 2px $themeMain,
      inset 1px 1px 3px $themeMain;
  }
}

.notification {
  @include flex(row, center, center);
  width: 100%;
  gap: 10px;
  padding: 20px 0;
  flex: 0 0 auto;
  position: relative;
}

.icon {
  align-items: flex-start;
  background-color: #d9d9d9;
  border-radius: 12px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 12px;
  position: relative;
}

.notiContent {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  position: relative;
}

.p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: $themeMain;
  display: -webkit-box;
  flex: 1;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.date {
  color: $darker-gray;
  font-family: $SfPro-regular;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.subTitle {
  align-self: stretch;
  color: $darker-gray;
  font-family: $SfPro-regular;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}

.head {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}
