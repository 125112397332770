@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.DialogContentBox {
  display: block;
  align-items: center;
  justify-content: flex-start;
  // margin: 30px 100px;
  gap: 10px;
}

.label {
  margin: 10px 0;
  color: $black;
  // background-color: $white-variant-2;
  font-family: $SfPro-semibold;
  font-size: $fs-18;
  font-weight: $fw-bold;
  line-height: 24px;
  text-wrap: pretty;
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  padding: 10px 10px;
}

.GridBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  background-color: #fff;
  border-radius: 30px;
  // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.AddButton {
  display: flex;
  justify-content: space-between;
  margin: 40px 0px;
}

.ButtonOne {
  width: auto;
  padding: 13px 50px;
  background-color: #f1f1f4;
  color: $black;
}
.ButtonTwo {
  width: auto;
  padding: 13px 50px;
  background-color: #7752fe;
  color: $white;
}

.button {
  border: 1px dashed #7752fe;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
  cursor: pointer;
  background-color: #f1eeff;
}

.deSelectButton {
  border: 1px dashed #7752fe;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
  cursor: pointer;
}
