@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

// Define variables
$regular-ff: 'SF Pro Display-Regular', Helvetica;
$bold-ff: 'SF Pro DisplayBold', Helvetica;

// Define mixins
@mixin mid-text($size, $weight, $height) {
  font-family: $regular-ff;
  font-size: $size;
  font-weight: $weight;
  letter-spacing: 0;
  line-height: $height;
}

.AvatarBox {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

// Use variables and mixins
.ContentHeadActionText {
  color: $darker-gray;
  @include mid-text(16px, 400, 12px);
  @include relative-position;
  @include fit-content;
}

.SubGrid {
  @include flex-row;
  @include flex-gap(10px);
  @include stretch-width;
}

.MainGrid {
  cursor: pointer;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #f1f1f4;
  width: 100%;
}

.ContentBox {
  @include flex-start;
  @include flex-column;
  @include flex-grow;
  @include flex-gap(20px);
  @include relative-position;
  overflow: hidden;
}

.ContentHeadBox {
  @include flex-start;
  @include flex-row;
  @include stretch-self;
  justify-content: space-between;
  @include relative-position;
}

.ContentHead {
  color: $subGrayText;
  height: 27px;
}

.ContentHeadNameText {
  color: $black;
  font-family: $bold-ff;
  font-size: $fs-20;
  font-weight: $fw-bold;
  letter-spacing: 0;
  line-height: 30px;
  height: 30px;
}

.ContentHeadActionBox {
  @include flex-center;
  @include flex-row;
  background-color: $gray;
  border-radius: 8px;
  @include flex-auto;
  @include flex-gap(10px);
  padding: 8px 15px;
  @include relative-position;
}

.ContentHeadSubText {
  @include stretch-self;
  color: $darker-gray;
  font-family: $regular-ff;
  font-size: $fs-18;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  @include relative-position;
}

.ContentTypeBox {
  @include flex-center;
  @include flex-row;
  background-color: #f1eeff;
  border-radius: 100px;
  @include flex-auto;
  @include flex-gap(12px);
  justify-content: center;
  padding: 2px 15px;
  margin-top: -8px;
  min-width: 98px;
  min-height: 23px;
  @include relative-position;
}

.ContentContactBox {
  @include flex-start;
  @include flex-row;
  @include stretch-self;
  @include flex-gap(30px);
  @include relative-position;
  @include stretch-width;
}

.divider {
  display: flex;
  width: 1px;
  background: #edeff4;
}

.ContentText {
  color: $subGrayText;
  @include mid-text(14px, 400, 20px);
  @include relative-position;
  @include fit-content;
  margin-top: -1px;
  white-space: nowrap;
}

.ContentContactSubBox {
  @include flex-start;
  @include flex-row;
  @include flex-auto;
  @include flex-gap(6px);
  @include relative-position;
}
