/* Customize the date range picker container */
.DateRangePicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #e40000;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Customize the date range picker input fields */
.DateRangePicker__InputGroup {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.DateRangePicker__InputGroup__Input {
  width: 150px;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  outline: none;
  // border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.DateRangePicker__InputGroup__Input:focus {
  // outline: none;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border: none;
}

/* Customize the calendar popup */
.react-calendar {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.react-calendar__navigation {
  margin-bottom: 20px;
}

.react-calendar__navigation button {
  font-size: 16px;
  color: #333;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.react-calendar__navigation button:hover {
  color: #000;
}

.react-calendar__tile {
  padding: 10px;
  font-size: 14px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.react-calendar__tile--now {
  background-color: #e6f7e6;
}

.react-calendar__tile--active {
  background-color: #b2bbe2;
  color: #fff;
}

.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  // border: thin solid gray;
  border: none;
  border-radius: 10px;
  // padding: 5px 0px;
  span {
    display: none;
  }
}

.react-daterange-picker__button {
  // display: none;
  border: 0;
  background: transparent;
  padding: 4px 6px;
  outline: none;
}

.react-daterange-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  height: 100%;
  flex-grow: 1;
  padding: 0px 5px;
  /* margin: 0px 1px; */
  box-sizing: content-box;
  width: 100%;
  display: none;
  outline: none;
  align-items: center;
  gap: 1px;
  visibility: hidden;
  span {
    display: none;
  }
}
