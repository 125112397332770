@import 'src/Styles/Mixin/index.scss';
@import 'src/Styles/Variable/index.scss';

.MainBox {
  padding: 30px;
}
.MainBox::-webkit-scrollbar {
  display: none;
}
.GridBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.GridContent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2%;
  width: 100%;
}

.chartGrid {
  width: 100%;
  flex-grow: 1;
  flex-basis: 600px;
}
