// Define colors as constants
@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

$primary-color: $themeMain;
$secondary-color: #6f737a;
$accent-color: $blue;
$background-color: #f8f9fb;
$border-color: $dark-gray;
$ellipse-color: #c8c9cc;

// Define fonts as constants
$main-font: 'SF UI Display-Semibold';
$secondary-font: 'SF Pro Display-Regular';
$main-font: 'SF Pro Display-Medium';
$main-font: 'SF Pro Display-Bold';
$secondary-font: 'SF Pro Display-Medium';

// Mixin for common styles
@mixin common-styles {
  font-family: $main-font;
  letter-spacing: 0;
  position: relative;
  white-space: break-spaces;
}

.HeadText {
  color: $blueMain;
  font-family: $SfPro-semibold;
  font-size: $fs-24;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.SubText {
  color: #4a5568;
  font-family: $SfPro-regular;
  font-weight: $fw-medium;

  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-align: center;
}

.LegalCard {
  width: 100%;
}
.div {
  @include common-styles;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.divTwo {
  @include common-styles;
  padding: 0px 0px 30px 0px;
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.textWrapper {
  font-family: $main-font;
  color: $primary-color;
  font-size: $fs-24;
  font-weight: $fw-semi-medium;
  line-height: 32px;
  margin-top: -1px;
  width: fit-content;
}

.divThree {
  @include common-styles;
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.textWrapperTwo {
  color: $secondary-color;
  font-family: $secondary-font;
  font-weight: $fw-medium;
  font-weight: $fw-regular;
  line-height: 20px;
  text-align: center;
  width: fit-content;
}

.icon-instance-node {
  height: 16px;
  position: relative;
  width: 16px;
}

.divFour {
  padding: 30px 30px;
  background-color: white;
  border-radius: 20px;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  height: 145px;
  width: 100%;
}

.left {
  @include common-styles;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  position: relative;
  flex-direction: column;
}

.textWrapperThree {
  color: $black;
  font-size: $fs-40;
  font-weight: $fw-semi-medium;
  line-height: 32px;
  width: fit-content;
}

.textWrapperFour {
  color: $darker-gray;
  font-size: $fs-18;
  font-weight: $fw-regular;
  line-height: 32px;
  width: fit-content;
}

.right {
  @include common-styles;
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  height: 40px;
  position: relative;
}

.filterTwo {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  padding: 8px 15px;
  position: relative;
}

.Add {
  align-items: center;
  align-self: stretch;
  background-color: $themeMain;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  padding: 8px 20px;
  margin: 0;
  font-size: 16px;
  // height: auto;
}
