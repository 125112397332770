@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

:root {
  --h1-bold-font-family: 'SF Pro Display', Helvetica;
  --h1-bold-font-size: 50px;
  --h1-bold-font-style: normal;
  --h1-bold-font-weight: 700;
  --h1-bold-letter-spacing: 0px;
  --h1-bold-line-height: 76px;
  --h1-light-font-family: 'SF Pro Display', Helvetica;
  --h1-light-font-size: 50px;
  --h1-light-font-style: normal;
  --h1-light-font-weight: 300;
  --h1-light-letter-spacing: 0px;
  --h1-light-line-height: 76px;
  --h1-medium-font-family: 'SF Pro Display', Helvetica;
  --h1-medium-font-size: 50px;
  --h1-medium-font-style: normal;
  --h1-medium-font-weight: 500;
  --h1-medium-letter-spacing: 0px;
  --h1-medium-line-height: 76px;
  --h1-regular-font-family: 'SF Pro Display', Helvetica;
  --h1-regular-font-size: 50px;
  --h1-regular-font-style: normal;
  --h1-regular-font-weight: 400;
  --h1-regular-letter-spacing: 0px;
  --h1-regular-line-height: 76px;
  --h1-semibold-font-family: 'SF Pro Display', Helvetica;
  --h1-semibold-font-size: 50px;
  --h1-semibold-font-style: normal;
  --h1-semibold-font-weight: 400;
  --h1-semibold-letter-spacing: 0px;
  --h1-semibold-line-height: 76px;
  --h1-thin-font-family: 'SF Pro Display', Helvetica;
  --h1-thin-font-size: 50px;
  --h1-thin-font-style: normal;
  --h1-thin-font-weight: 100;
  --h1-thin-letter-spacing: 0px;
  --h1-thin-line-height: 76px;
  --h2-bold-font-family: 'SF Pro Display', Helvetica;
  --h2-bold-font-size: 42px;
  --h2-bold-font-style: normal;
  --h2-bold-font-weight: 700;
  --h2-bold-letter-spacing: 0px;
  --h2-bold-line-height: 60px;
  --h2-light-font-family: 'SF Pro Display', Helvetica;
  --h2-light-font-size: 42px;
  --h2-light-font-style: normal;
  --h2-light-font-weight: 300;
  --h2-light-letter-spacing: 0px;
  --h2-light-line-height: 60px;
  --h2-medium-font-family: 'SF Pro Display', Helvetica;
  --h2-medium-font-size: 42px;
  --h2-medium-font-style: normal;
  --h2-medium-font-weight: 500;
  --h2-medium-letter-spacing: 0px;
  --h2-medium-line-height: 60px;
  --h2-regular-font-family: 'SF Pro Display', Helvetica;
  --h2-regular-font-size: 42px;
  --h2-regular-font-style: normal;
  --h2-regular-font-weight: 400;
  --h2-regular-letter-spacing: 0px;
  --h2-regular-line-height: 60px;
  --h2-semibold-font-family: 'SF Pro Display', Helvetica;
  --h2-semibold-font-size: 42px;
  --h2-semibold-font-style: normal;
  --h2-semibold-font-weight: 400;
  --h2-semibold-letter-spacing: 0px;
  --h2-semibold-line-height: 60px;
  --h2-thin-font-family: 'SF Pro Display', Helvetica;
  --h2-thin-font-size: 42px;
  --h2-thin-font-style: normal;
  --h2-thin-font-weight: 100;
  --h2-thin-letter-spacing: 0px;
  --h2-thin-line-height: 60px;
  --h3-bold-font-family: 'SF Pro Display', Helvetica;
  --h3-bold-font-size: 36px;
  --h3-bold-font-style: normal;
  --h3-bold-font-weight: 700;
  --h3-bold-letter-spacing: 0px;
  --h3-bold-line-height: 50px;
  --h3-light-font-family: 'SF Pro Display', Helvetica;
  --h3-light-font-size: 36px;
  --h3-light-font-style: normal;
  --h3-light-font-weight: 300;
  --h3-light-letter-spacing: 0px;
  --h3-light-line-height: 50px;
  --h3-medium-font-family: 'SF Pro Display', Helvetica;
  --h3-medium-font-size: 36px;
  --h3-medium-font-style: normal;
  --h3-medium-font-weight: 500;
  --h3-medium-letter-spacing: 0px;
  --h3-medium-line-height: 50px;
  --h3-regular-font-family: 'SF Pro Display', Helvetica;
  --h3-regular-font-size: 36px;
  --h3-regular-font-style: normal;
  --h3-regular-font-weight: 400;
  --h3-regular-letter-spacing: 0px;
  --h3-regular-line-height: 50px;
  --h3-semibold-font-family: 'SF Pro Display', Helvetica;
  --h3-semibold-font-size: 36px;
  --h3-semibold-font-style: normal;
  --h3-semibold-font-weight: 400;
  --h3-semibold-letter-spacing: 0px;
  --h3-semibold-line-height: 50px;
  --h3-thin-font-family: 'SF Pro Display', Helvetica;
  --h3-thin-font-size: 36px;
  --h3-thin-font-style: normal;
  --h3-thin-font-weight: 100;
  --h3-thin-letter-spacing: 0px;
  --h3-thin-line-height: 50px;
  --h4-bold-font-family: 'SF Pro Display', Helvetica;
  --h4-bold-font-size: 32px;
  --h4-bold-font-style: normal;
  --h4-bold-font-weight: 700;
  --h4-bold-letter-spacing: 0px;
  --h4-bold-line-height: 46px;
  --h4-light-font-family: 'SF Pro Display', Helvetica;
  --h4-light-font-size: 32px;
  --h4-light-font-style: normal;
  --h4-light-font-weight: 300;
  --h4-light-letter-spacing: 0px;
  --h4-light-line-height: 46px;
  --h4-medium-font-family: 'SF Pro Display', Helvetica;
  --h4-medium-font-size: 32px;
  --h4-medium-font-style: normal;
  --h4-medium-font-weight: 500;
  --h4-medium-letter-spacing: 0px;
  --h4-medium-line-height: 46px;
  --h4-regular-font-family: 'SF Pro Display', Helvetica;
  --h4-regular-font-size: 32px;
  --h4-regular-font-style: normal;
  --h4-regular-font-weight: 400;
  --h4-regular-letter-spacing: 0px;
  --h4-regular-line-height: 46px;
  --h4-semibold-font-family: 'SF Pro Display', Helvetica;
  --h4-semibold-font-size: 32px;
  --h4-semibold-font-style: normal;
  --h4-semibold-font-weight: 400;
  --h4-semibold-letter-spacing: 0px;
  --h4-semibold-line-height: 46px;
  --h4-thin-font-family: 'SF Pro Display', Helvetica;
  --h4-thin-font-size: 32px;
  --h4-thin-font-style: normal;
  --h4-thin-font-weight: 100;
  --h4-thin-letter-spacing: 0px;
  --h4-thin-line-height: 46px;
  --h5-bold-font-family: 'SF Pro Display', Helvetica;
  --h5-bold-font-size: 28px;
  --h5-bold-font-style: normal;
  --h5-bold-font-weight: 700;
  --h5-bold-letter-spacing: 0px;
  --h5-bold-line-height: 40px;
  --h5-light-font-family: 'SF Pro Display', Helvetica;
  --h5-light-font-size: 28px;
  --h5-light-font-style: normal;
  --h5-light-font-weight: 300;
  --h5-light-letter-spacing: 0px;
  --h5-light-line-height: 40px;
  --h5-medium-font-family: 'SF Pro Display', Helvetica;
  --h5-medium-font-size: 28px;
  --h5-medium-font-style: normal;
  --h5-medium-font-weight: 500;
  --h5-medium-letter-spacing: 0px;
  --h5-medium-line-height: 40px;
  --h5-regular-font-family: 'SF Pro Display', Helvetica;
  --h5-regular-font-size: 28px;
  --h5-regular-font-style: normal;
  --h5-regular-font-weight: 400;
  --h5-regular-letter-spacing: 0px;
  --h5-regular-line-height: 40px;
  --h5-semibold-font-family: 'SF Pro Display', Helvetica;
  --h5-semibold-font-size: 28px;
  --h5-semibold-font-style: normal;
  --h5-semibold-font-weight: 400;
  --h5-semibold-letter-spacing: 0px;
  --h5-semibold-line-height: 40px;
  --h5-thin-font-family: 'SF Pro Display', Helvetica;
  --h5-thin-font-size: 28px;
  --h5-thin-font-style: normal;
  --h5-thin-font-weight: 100;
  --h5-thin-letter-spacing: 0px;
  --h5-thin-line-height: 40px;
  --h6-bold-font-family: 'SF Pro Display', Helvetica;
  --h6-bold-font-size: 24px;
  --h6-bold-font-style: normal;
  --h6-bold-font-weight: 700;
  --h6-bold-letter-spacing: 0px;
  --h6-bold-line-height: 32px;
  --h6-light-font-family: 'SF Pro Display', Helvetica;
  --h6-light-font-size: 24px;
  --h6-light-font-style: normal;
  --h6-light-font-weight: 300;
  --h6-light-letter-spacing: 0px;
  --h6-light-line-height: 32px;
  --h6-medium-font-family: 'SF Pro Display', Helvetica;
  --h6-medium-font-size: 24px;
  --h6-medium-font-style: normal;
  --h6-medium-font-weight: 500;
  --h6-medium-letter-spacing: 0px;
  --h6-medium-line-height: 32px;
  --h6-regular-font-family: 'SF Pro Display', Helvetica;
  --h6-regular-font-size: 24px;
  --h6-regular-font-style: normal;
  --h6-regular-font-weight: 400;
  --h6-regular-letter-spacing: 0px;
  --h6-regular-line-height: 32px;
  --h6-semibold-font-family: 'SF Pro Display', Helvetica;
  --h6-semibold-font-size: 24px;
  --h6-semibold-font-style: normal;
  --h6-semibold-font-weight: 400;
  --h6-semibold-letter-spacing: 0px;
  --h6-semibold-line-height: 32px;
  --h6-thin-font-family: 'SF Pro Display', Helvetica;
  --h6-thin-font-size: 24px;
  --h6-thin-font-style: normal;
  --h6-thin-font-weight: 100;
  --h6-thin-letter-spacing: 0px;
  --h6-thin-line-height: 32px;
  --neutralgray-100: rgba(255, 255, 255, 1);
  --neutralgray-1000: rgba(7, 20, 55, 1);
  --neutralgray-200: rgba(249, 249, 249, 1);
  --neutralgray-300: rgba(241, 241, 244, 1);
  --neutralgray-400: rgba(219, 223, 233, 1);
  --neutralgray-500: rgba(196, 202, 218, 1);
  --neutralgray-600: rgba(153, 161, 183, 1);
  --neutralgray-700: rgba(120, 130, 157, 1);
  --neutralgray-800: rgba(75, 86, 117, 1);
  --neutralgray-900: rgba(37, 47, 74, 1);
  --primary-shades-tints10: rgba(241, 238, 255, 1);
  --primary-shades-tints100: rgba(119, 82, 254, 1);
  --primary-shades-tints20: rgba(228, 220, 255, 1);
  --primary-shades-tints200: rgba(93, 56, 228, 1);
  --primary-shades-tints30: rgba(214, 203, 255, 1);
  --primary-shades-tints300: rgba(68, 31, 203, 1);
  --primary-shades-tints40: rgba(201, 186, 255, 1);
  --primary-shades-tints400: rgba(42, 5, 177, 1);
  --primary-shades-tints50: rgba(187, 168, 254, 1);
  --primary-shades-tints500: rgba(17, 0, 152, 1);
  --primary-shades-tints60: rgba(173, 151, 254, 1);
  --primary-shades-tints600: rgba(0, 0, 126, 1);
  --primary-shades-tints70: rgba(160, 134, 254, 1);
  --primary-shades-tints700: rgba(0, 0, 101, 1);
  --primary-shades-tints80: rgba(146, 117, 254, 1);
  --primary-shades-tints800: rgba(0, 0, 75, 1);
  --primary-shades-tints90: rgba(133, 99, 254, 1);
  --primary-shades-tints900: rgba(0, 0, 50, 1);
  --text-caption-bold-font-family: 'SF Pro Display', Helvetica;
  --text-caption-bold-font-size: 14px;
  --text-caption-bold-font-style: normal;
  --text-caption-bold-font-weight: 700;
  --text-caption-bold-letter-spacing: 0px;
  --text-caption-bold-line-height: 20px;
  --text-caption-light-font-family: 'SF Pro Display', Helvetica;
  --text-caption-light-font-size: 14px;
  --text-caption-light-font-style: normal;
  --text-caption-light-font-weight: 300;
  --text-caption-light-letter-spacing: 0px;
  --text-caption-light-line-height: 18px;
  --text-caption-medium-font-family: 'SF Pro Display', Helvetica;
  --text-caption-medium-font-size: 14px;
  --text-caption-medium-font-style: normal;
  --text-caption-medium-font-weight: 500;
  --text-caption-medium-letter-spacing: 0px;
  --text-caption-medium-line-height: 20px;
  --text-caption-regular-font-family: 'SF Pro Display', Helvetica;
  --text-caption-regular-font-size: 14px;
  --text-caption-regular-font-style: normal;
  --text-caption-regular-font-weight: 400;
  --text-caption-regular-letter-spacing: 0px;
  --text-caption-regular-line-height: 20px;
  --text-caption-semibold-font-family: 'SF Pro Display', Helvetica;
  --text-caption-semibold-font-size: 14px;
  --text-caption-semibold-font-style: normal;
  --text-caption-semibold-font-weight: 400;
  --text-caption-semibold-letter-spacing: 0px;
  --text-caption-semibold-line-height: 20px;
  --text-caption-thin-font-family: 'SF Pro Display', Helvetica;
  --text-caption-thin-font-size: 14px;
  --text-caption-thin-font-style: normal;
  --text-caption-thin-font-weight: 100;
  --text-caption-thin-letter-spacing: 0px;
  --text-caption-thin-line-height: 20px;
  --text-large-bold-font-family: 'SF Pro Display', Helvetica;
  --text-large-bold-font-size: 20px;
  --text-large-bold-font-style: normal;
  --text-large-bold-font-weight: 700;
  --text-large-bold-letter-spacing: 0px;
  --text-large-bold-line-height: 30px;
  --text-large-light-font-family: 'SF Pro Display', Helvetica;
  --text-large-light-font-size: 20px;
  --text-large-light-font-style: normal;
  --text-large-light-font-weight: 300;
  --text-large-light-letter-spacing: 0px;
  --text-large-light-line-height: 26px;
  --text-large-medium-font-family: 'SF Pro Display', Helvetica;
  --text-large-medium-font-size: 20px;
  --text-large-medium-font-style: normal;
  --text-large-medium-font-weight: 500;
  --text-large-medium-letter-spacing: 0px;
  --text-large-medium-line-height: 30px;
  --text-large-regular-font-family: 'SF Pro Display', Helvetica;
  --text-large-regular-font-size: 20px;
  --text-large-regular-font-style: normal;
  --text-large-regular-font-weight: 400;
  --text-large-regular-letter-spacing: 0px;
  --text-large-regular-line-height: 30px;
  --text-large-semibold-font-family: 'SF Pro Display', Helvetica;
  --text-large-semibold-font-size: 20px;
  --text-large-semibold-font-style: normal;
  --text-large-semibold-font-weight: 400;
  --text-large-semibold-letter-spacing: 0px;
  --text-large-semibold-line-height: 30px;
  --text-large-thin-font-family: 'SF Pro Display', Helvetica;
  --text-large-thin-font-size: 20px;
  --text-large-thin-font-style: normal;
  --text-large-thin-font-weight: 100;
  --text-large-thin-letter-spacing: 0px;
  --text-large-thin-line-height: 30px;
  --text-medium-bold-font-family: 'SF Pro Display', Helvetica;
  --text-medium-bold-font-size: 18px;
  --text-medium-bold-font-style: normal;
  --text-medium-bold-font-weight: 700;
  --text-medium-bold-letter-spacing: 0px;
  --text-medium-bold-line-height: 24px;
  --text-medium-light-font-family: 'SF Pro Display', Helvetica;
  --text-medium-light-font-size: 18px;
  --text-medium-light-font-style: normal;
  --text-medium-light-font-weight: 300;
  --text-medium-light-letter-spacing: 0px;
  --text-medium-light-line-height: 24px;
  --text-medium-medium-font-family: 'SF Pro Display', Helvetica;
  --text-medium-medium-font-size: 18px;
  --text-medium-medium-font-style: normal;
  --text-medium-medium-font-weight: 500;
  --text-medium-medium-letter-spacing: 0px;
  --text-medium-medium-line-height: 24px;
  --text-medium-regular-font-family: 'SF Pro Display', Helvetica;
  --text-medium-regular-font-size: 18px;
  --text-medium-regular-font-style: normal;
  --text-medium-regular-font-weight: 400;
  --text-medium-regular-letter-spacing: 0px;
  --text-medium-regular-line-height: 24px;
  --text-medium-semibold-font-family: 'SF Pro Display', Helvetica;
  --text-medium-semibold-font-size: 18px;
  --text-medium-semibold-font-style: normal;
  --text-medium-semibold-font-weight: 400;
  --text-medium-semibold-letter-spacing: 0px;
  --text-medium-semibold-line-height: 24px;
  --text-medium-thin-font-family: 'SF Pro Display', Helvetica;
  --text-medium-thin-font-size: 18px;
  --text-medium-thin-font-style: normal;
  --text-medium-thin-font-weight: 100;
  --text-medium-thin-letter-spacing: 0px;
  --text-medium-thin-line-height: 24px;
  --text-regular-bold-font-family: 'SF Pro Display', Helvetica;
  --text-regular-bold-font-size: 16px;
  --text-regular-bold-font-style: normal;
  --text-regular-bold-font-weight: 700;
  --text-regular-bold-letter-spacing: 0px;
  --text-regular-bold-line-height: 24px;
  --text-regular-light-font-family: 'SF Pro Display', Helvetica;
  --text-regular-light-font-size: 16px;
  --text-regular-light-font-style: normal;
  --text-regular-light-font-weight: 300;
  --text-regular-light-letter-spacing: 0px;
  --text-regular-light-line-height: 22px;
  --text-regular-medium-font-family: 'SF Pro Display', Helvetica;
  --text-regular-medium-font-size: 16px;
  --text-regular-medium-font-style: normal;
  --text-regular-medium-font-weight: 500;
  --text-regular-medium-letter-spacing: 0px;
  --text-regular-medium-line-height: 24px;
  --text-regular-regular-font-family: 'SF Pro Display', Helvetica;
  --text-regular-regular-font-size: 16px;
  --text-regular-regular-font-style: normal;
  --text-regular-regular-font-weight: 400;
  --text-regular-regular-letter-spacing: 0px;
  --text-regular-regular-line-height: 24px;
  --text-regular-semibold-font-family: 'SF Pro Display', Helvetica;
  --text-regular-semibold-font-size: 16px;
  --text-regular-semibold-font-style: normal;
  --text-regular-semibold-font-weight: 400;
  --text-regular-semibold-letter-spacing: 0px;
  --text-regular-semibold-line-height: 24px;
  --text-regular-thin-font-family: 'SF Pro Display', Helvetica;
  --text-regular-thin-font-size: 16px;
  --text-regular-thin-font-style: normal;
  --text-regular-thin-font-weight: 100;
  --text-regular-thin-letter-spacing: 0px;
  --text-regular-thin-line-height: 24px;
  --text-small-bold-font-family: 'SF Pro Display', Helvetica;
  --text-small-bold-font-size: 12px;
  --text-small-bold-font-style: normal;
  --text-small-bold-font-weight: 700;
  --text-small-bold-letter-spacing: 0px;
  --text-small-bold-line-height: 16px;
  --text-small-light-font-family: 'SF Pro Display', Helvetica;
  --text-small-light-font-size: 12px;
  --text-small-light-font-style: normal;
  --text-small-light-font-weight: 300;
  --text-small-light-letter-spacing: 0px;
  --text-small-light-line-height: 16px;
  --text-small-medium-font-family: 'SF Pro Display', Helvetica;
  --text-small-medium-font-size: 12px;
  --text-small-medium-font-style: normal;
  --text-small-medium-font-weight: 500;
  --text-small-medium-letter-spacing: 0px;
  --text-small-medium-line-height: 16px;
  --text-small-regular-font-family: 'SF Pro Display', Helvetica;
  --text-small-regular-font-size: 12px;
  --text-small-regular-font-style: normal;
  --text-small-regular-font-weight: 400;
  --text-small-regular-letter-spacing: 0px;
  --text-small-regular-line-height: 16px;
  --text-small-semibold-font-family: 'SF Pro Display', Helvetica;
  --text-small-semibold-font-size: 12px;
  --text-small-semibold-font-style: normal;
  --text-small-semibold-font-weight: 400;
  --text-small-semibold-letter-spacing: 0px;
  --text-small-semibold-line-height: 16px;
  --text-small-thin-font-family: 'SF Pro Display', Helvetica;
  --text-small-thin-font-size: 12px;
  --text-small-thin-font-style: normal;
  --text-small-thin-font-weight: 100;
  --text-small-thin-letter-spacing: 0px;
  --text-small-thin-line-height: 16px;
  --text-XL-bold-font-family: 'SF Pro Display', Helvetica;
  --text-XL-bold-font-size: 22px;
  --text-XL-bold-font-style: normal;
  --text-XL-bold-font-weight: 700;
  --text-XL-bold-letter-spacing: 0px;
  --text-XL-bold-line-height: 30px;
  --text-XL-light-font-family: 'SF Pro Display', Helvetica;
  --text-XL-light-font-size: 22px;
  --text-XL-light-font-style: normal;
  --text-XL-light-font-weight: 300;
  --text-XL-light-letter-spacing: 0px;
  --text-XL-light-line-height: 30px;
  --text-XL-medium-font-family: 'SF Pro Display', Helvetica;
  --text-XL-medium-font-size: 22px;
  --text-XL-medium-font-style: normal;
  --text-XL-medium-font-weight: 500;
  --text-XL-medium-letter-spacing: 0px;
  --text-XL-medium-line-height: 30px;
  --text-XL-regular-font-family: 'SF Pro Display', Helvetica;
  --text-XL-regular-font-size: 22px;
  --text-XL-regular-font-style: normal;
  --text-XL-regular-font-weight: 400;
  --text-XL-regular-letter-spacing: 0px;
  --text-XL-regular-line-height: 30px;
  --text-XL-semibold-font-family: 'SF Pro Display', Helvetica;
  --text-XL-semibold-font-size: 22px;
  --text-XL-semibold-font-style: normal;
  --text-XL-semibold-font-weight: 400;
  --text-XL-semibold-letter-spacing: 0px;
  --text-XL-semibold-line-height: 30px;
  --text-XL-thin-font-family: 'SF Pro Display', Helvetica;
  --text-XL-thin-font-size: 22px;
  --text-XL-thin-font-style: normal;
  --text-XL-thin-font-weight: 100;
  --text-XL-thin-letter-spacing: 0px;
  --text-XL-thin-line-height: 30px;
}

.manageEmployeeCard {
  align-items: flex-start;
  background-color: #ffffff;
  border: 0.88px solid;
  border-color: #f1f1f4;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 14px;
  position: relative;
  width: calc(50% - 15px);
  margin: 5px 0 5px 10px;
}

.frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14.16px;
  position: relative;
  width: 100%;
}

.div2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.62px;
  height: 26.54px;
  position: relative;
}

.textWrapper {
  color: $blueMain;
  font-family: var(--text-medium-semibold-font-family);
  font-size: var(--text-medium-semibold-font-size);
  font-style: var(--text-medium-semibold-font-style);
  font-weight: var(--text-medium-semibold-font-weight);
  letter-spacing: var(--text-medium-semibold-letter-spacing);
  line-height: var(--text-medium-semibold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.rectangle {
  background-color: #d9d9d9;
  border-radius: 17.69px;
  height: 4.42px;
  position: relative;
  width: 4.42px;
}

.textWrapper2 {
  color: var(--neutralgray-700);
  font-family: var(--text-caption-medium-font-family);
  font-size: var(--text-caption-medium-font-size);
  font-style: var(--text-caption-medium-font-style);
  font-weight: var(--text-caption-medium-font-weight);
  letter-spacing: var(--text-caption-medium-letter-spacing);
  line-height: var(--text-caption-medium-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.info {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.62px;
  position: relative;
  width: 100%;
}

.div3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.31px;
  position: relative;
}

.iconInstanceNode {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.textWrapper3 {
  color: var(--neutralgray-700);
  font-family: var(--text-small-medium-font-family);
  font-size: var(--text-small-medium-font-size);
  font-style: var(--text-small-medium-font-style);
  font-weight: var(--text-small-medium-font-weight);
  letter-spacing: var(--text-small-medium-letter-spacing);
  line-height: var(--text-small-medium-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10.62px;
  position: relative;
  width: 100%;
}

.email {
  align-items: center;
  display: flex;
  gap: 5.31px;
  position: relative;
  width: 130.94px;
}

.ellysePerryGmail {
  background: transparent;
  border: none;
  color: var(--neutralgray-700);
  font-family: var(--text-small-medium-font-family);
  font-size: var(--text-small-medium-font-size);
  font-style: var(--text-small-medium-font-style);
  font-weight: var(--text-small-medium-font-weight);
  letter-spacing: var(--text-small-medium-letter-spacing);
  line-height: var(--text-small-medium-line-height);
  margin-right: -15.37px;
  padding: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.rectangle2 {
  align-self: stretch;
  border: 0.88px dashed;
  border-color: var(--neutralgray-400);
  height: 1px;
  position: relative;
  width: 100%;
}

.user {
  background-blend-mode: luminosity;
  height: 33.54px;
  margin-bottom: -0.84px;
  margin-left: -1.77px;
  margin-top: -0.84px;
  position: relative;
  width: 33.54px;
}

.info2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7.08px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.divWrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 3.54px;
  position: relative;
  width: 100%;
}

.textWrapper4 {
  color: var(--neutralgray-700);
  font-family: var(--text-small-medium-font-family);
  font-size: var(--text-small-medium-font-size);
  font-style: var(--text-small-medium-font-style);
  font-weight: var(--text-small-medium-font-weight);
  letter-spacing: var(--text-small-medium-letter-spacing);
  line-height: var(--text-small-medium-line-height);
  margin-top: -0.88px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.bar {
  align-items: flex-start;
  align-self: stretch;
  background-color: #f2f4f8;
  border-radius: 26.54px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.85px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.holder {
  background-color: var(--primary-shades-tints100);
  border-radius: 26.54px;
  height: 3.54px;
  position: relative;
  width: 156.59px;
}

.frame3 {
  align-items: center;
  background-color: #e3e3e5;
  border-radius: 3.54px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 6px;
  position: relative;
}

.frame4 {
  align-items: center;
  background-color: #e3e3e5;
  border-radius: 3.54px;
  display: inline-flex;
  justify-content: center;
  padding: 2px;
  position: relative;
}

.textWrapper5 {
  color: var(--neutralgray-700);
  font-family: var(--text-small-regular-font-family);
  font-size: var(--text-small-regular-font-size);
  font-style: var(--text-small-regular-font-style);
  font-weight: var(--text-small-regular-font-weight);
  letter-spacing: var(--text-small-regular-letter-spacing);
  line-height: var(--text-small-regular-line-height);
  margin-top: -0.88px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.statusTagInstance {
  border-radius: 7.08px !important;
  flex: 0 0 auto !important;
  gap: 8px !important;
  height: 31.85px !important;
  min-width: 81.39px !important;
  padding: 5.31px 10px !important;
}

.designComponentInstanceNode {
  font-family: var(--text-small-semibold-font-family) !important;
  font-size: var(--text-small-semibold-font-size) !important;
  font-style: var(--text-small-semibold-font-style) !important;
  font-weight: var(--text-small-semibold-font-weight) !important;
  letter-spacing: var(--text-small-semibold-letter-spacing) !important;
  line-height: var(--text-small-semibold-line-height) !important;
}

.iconInProgress1 {
  height: 14.16px !important;
  position: relative !important;
  width: 14.16px !important;
}
