@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.Main {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  position: relative;
}

.Head {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 30px;
  position: relative;
  width: 100%;
}

.HeadText {
  color: $themeMain;
  font-family: $SfPro-semibold;
  font-size: $fs-24;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.EditContact {
  // padding: 30px;
  align-items: flex-start;
  background-color: $white;
  border: 1px solid;
  border-color: transparent;
  display: flex;
  flex-direction: column;
  position: relative;
  height: -webkit-fill-available;
}

.Form {
  align-items: flex-start;
  align-self: stretch;
  background-color: $white;
  border-radius: 20px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  // padding: 30px;
  position: relative;
  width: 100%;
}

.Element {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  position: relative;
  width: 100%;
}

.Question {
  align-items: center;
  display: flex;
  gap: 25px;
  position: relative;
  width: 460px;
}

.EditContactDiv {
  color: #071437;
  font-family: $SfPro-semibold;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.Ans {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  position: relative;
}
.AnsTxt {
  input {
    color: $subGrayText;
    background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
  div[role='combobox'] {
    color: $subGrayText;
    background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
  input[disabled] {
    color: $subGrayText;
    background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
}

.EditContactImage {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.EditContactLogo {
  border: 1px solid;
  border-color: #e1e4eb;
  border-radius: 15px;
  height: 160px;
  position: relative;
  width: 160px;
}

.overlapGroup {
  height: 180px;
  left: -2px;
  position: relative;
  top: -10px;
  width: 172px;
}

.Rectangle {
  background-blend-mode: luminosity;
  height: 164px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 164px;
  background-color: $dark-gray;
  border-radius: 10px;
}

.IconEditWrapper {
  align-items: center;
  background-color: #e1e4eb;
  border-radius: 30px;
  display: flex;
  gap: 10px;
  height: 32px;
  justify-content: center;
  left: 140px;
  padding: 4px;
  position: absolute;
  top: 0;
  width: 32px;
  cursor: pointer;

  .EditIcon {
    cursor: pointer;
  }
}

.inputFile {
  cursor: pointer;
  display: flex;
  position: absolute;
  height: 32px;
  width: 32px;
  opacity: 0;
}
::-webkit-file-upload-button {
  display: none;
}

.IconInstanceNode {
  height: 16px;
  position: relative;
  width: 16px;
}

.IconDeleteWrapper {
  align-items: center;
  background-color: #e1e4eb;
  border-radius: 30px;
  display: flex;
  gap: 10px;
  height: 32px;
  justify-content: center;
  left: 140px;
  padding: 4px;
  position: absolute;
  top: 148px;
  width: 32px;
  cursor: pointer;
}

.edit-contact .icon-delete {
  height: 24px;
  position: relative;
  width: 24px;
}

.Description {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.DescriptionP {
  color: $blue;
  font-family: $SfPro-regular;
  font-weight: $fw-medium;

  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.Field {
  align-items: center;
  background-color: $gray;
  border: 1.5px solid;
  border-color: var(--blueblue-2);
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  height: 50px;
  padding: 13px 15px;
  position: relative;
}

.TextWrapper2 {
  color: $darker-gray;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.edit-contact .input {
  background-color: $gray;
  border: 1.5px solid;
  border-color: $dark-gray;
  border-radius: 10px;
  color: $darker-gray;
  flex-grow: 1;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-regular;
  height: 50px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  padding: 13px 15px;
  position: relative;
  white-space: nowrap;
}

.Field2 {
  align-items: center;
  background-color: $gray;
  border: 1.5px solid;
  border-color: var(--blueblue-2);
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 50px;
  justify-content: space-between;
  padding: 13px 15px;
  position: relative;
}

.Action {
  align-items: center;
  align-self: stretch;
  border-color: #e1e4eb;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: flex-end;
  padding: 20px 30px;
  position: relative;
  width: 100%;
  border-top: dashed 1px #6a90eb;
  margin-top: 100px;
}

.ActionButton {
  all: unset;
  align-items: center;
  background-color: $dark-gray;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 13px 15px;
  position: relative;
  width: 160px;
}

.TextWrapper3 {
  color: $themeMain;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  opacity: 0.7;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.DivWrapper {
  all: unset;
  align-items: center;
  background-color: $themeMain;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 13px 15px;
  position: relative;
  width: 160px;
}

.TextWrapper4 {
  color: $white;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;

  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.field {
  @extend %field;
}
