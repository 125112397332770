@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.OrganizationBox {
  display: block;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
  height: 100%;
  width: 74.5%;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #f1f1f4;
}

.OrganizationTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-family: $SfPro-semibold;
  margin-bottom: 20px;
  cursor: pointer;
}

.OrganizationBoxSub {
  display: block;
  align-items: flex-start;
  height: 200px;
  width: 100%;
  border-radius: 20px;
  background-color: white;
  cursor: pointer;
}

.OrganizationGrid {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.OrganizationGridBox {
  border: 1px solid #dadce0;
  border-radius: 4px;
  height: 52px;
  width: 52px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OrganizationGridTitle {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-family: $SfPro-semibold;
}

.OrganizationGridLabel {
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: $SfPro-regular;
}

.OrganizationGridDec {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: $SfPro-regular;
  color: $darker-gray;
}

.OrganizationGridLine {
  border-color: $darker-gray;
  border-bottom: 1px dotted black;
  margin: 10px 0;
}

.OrganizationGridName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-family: 'SFProDisplaymedium';
}

.OrganizationGridDot {
  height: '5px';
  width: '5px';
  background-color: $coffee;
  border-radius: '50%';
  padding: '5px';
  margin: '5px';
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
}

.OrganizationGridTxt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-family: $SfPro-regular;
}

.OrganizationGridTxtEnd {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 16px;
  font-family: $SfPro-regular;
}

.Dot {
  height: 4px;
  width: 4px;
  background-color: #c4cada;
  border-radius: 50%;
  padding: 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
