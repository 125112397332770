@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.fontBold {
    font-weight: 700;
}
.fontColorBlack {
    color:themeMain;
}
.StyledLabel{
    font-size: 16px;
}
.sticky {
   position: '-webkit-sticky';
   position: sticky;
   top: 0;
}
.Box1{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.Box2{
  display: flex;
 justify-content: 'space-between';
  gap: 14px;
}
.semiBoldText {
  color: $themeMain;
  text-align: center;
  flex-grow: 1;
  font-size: $fs-16;
  font-weight: $fw-medium;
}

.OtherText {
  color: #6f737a;
   font-size: 14px;
  line-height: 143%;
}

.AvatarBox {
   display: flex;
   justify-content: 'space-between';
    gap: 10px;
}


.AppPageBox {
     align-items: flex-start;
     background-color: $white;
     border: 1px solid transparent;
     border-radius: 20px;
     display: flex;
     flex-direction: column;
    //  position: relative;
}

.AppHeaderPaper {
    align-items: center;
       background-color: $white;
       align-self: stretch;
       border-color: #e1e4eb;
       display: flex;
       gap: 10px;
       height: 70px;
       border-radius: 15px;
       padding: 15px 30px;
       position: relative;
       width: 100%;
}

.TitleFont {
      color: $themeMain;
     font-size: $fs-20;
     font-weight: $fw-bold;
     letter-spacing: 0;
     line-height: 24px;
     position: relative;
     white-space: nowrap;
}

.GridStyle {
    background: $white;
     border-radius: 15px;
}
.verticalDevider {
    border-right: 1px solid #e1e4eb;
}

.ContentGridStyle {
    border-top: 1px solid #e1e4eb;
    overflow-y: auto; 
    overflow-x: auto; 
    max-height: 70vh; 
}

.GridScroll{
    max-height: 120vh;
    overflow-y: auto;
}
