@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.Main {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  position: relative;
  padding: 30px;
}

.ListWrapper {
  align-items: flex-start;
  align-self: stretch;
  // display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.ListWrapperSub {
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: stretch;
  flex-wrap: wrap;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
}

.ListGrid {
  align-items: flex-start;
  // display: flex;
  flex: 1 1;
  /* gap: 20px 20px; */
  position: relative;
  border-radius: 15px;
  flex-grow: 1;
  flex-wrap: wrap;
  // padding: 0 0 20px 20px;
}

.Head {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 30px;
  position: relative;
  width: 100%;
}

.HeadText {
  color: #190482;
  font-family: $SfPro-semibold;
  font-size: $fs-24;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.SubText {
  color: #4a5568;
  font-family: $SfPro-regular;
  font-weight: $fw-medium;

  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-align: center;
}

.MidText {
  color: #4a5568;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.MidText2 {
  color: $white;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.Add {
  align-items: center;
  align-self: stretch;
  background-color: $themeMain;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  padding: 8px 20px;
  margin: 0;
  height: auto;
}

.Right {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  height: 40px;
  position: relative;
}

.Left {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  position: relative;
}

.Box1 {
  align-items: center;
  background-color: #f8f9fb;
  border: 1px solid;
  border-color: $dark-gray;
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  padding: 8px 15px;
  position: relative;
}

.Main2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  justify-content: center;
  position: relative;
}

.SearchBox {
  align-items: center;
  background-color: $white;
  border: 1px solid;
  border-color: $dark-gray;
  border-radius: 10px;
  display: flex;
  gap: 6px;
  padding: 8px 15px;
  position: relative;
  width: 300px;
}

.Action {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.SearchEm {
  background: transparent;
  border: none;
  border-bottom: none;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
}

.SearchEm > input {
  padding: 0;
}

.div {
  align-content: flex-start;
  flex-wrap: wrap;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.flexStartBox {
  width: 100%;
  // gap: 20px;
  display: flex;
}

.flexSpaceBox {
  padding: 10px;
}

@media (max-width: 991px) {
  .flexSpaceBox {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}

.column {
  display: grid;
  height: 100%;
  line-height: normal;
}

.whiteContainer {
  border-radius: 20px;
  background-color: $white;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-weight: $fw-medium;

  color: $themeMain;
  font-weight: $fw-regular;
  width: 100%;
  padding: 30px;
}

.div5 {
  font-size: $fs-18;
  font-family: $SfPro-semibold;
  color: $themeMain;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.div7 {
  margin: 20px 1px;
  height: 1px;
  border: dashed 1px $blue;
}

.div6 {
  font-weight: $fw-medium;
  font-family: $SfPro-regular;
  color: $blue;
  // margin-top: 10px;
}

.div8 {
  font-size: $fs-16;
  font-family: $SfPro-medium;
}

.div9 {
  display: flex;
  gap: 20px;
  margin: 10px 1px;
}

.div10 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: $fw-medium;

  // text-wrap: nowrap;
}

.Avatar {
  height: 36px;
  width: 36px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.RoleBox1 {
  background-color: $white;
  padding: 30px;
  width: 100%;
  height: 620px;
  border-radius: 20px;
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  position: relative;
}

.mainprofiledetail {
  align-items: flex-start;
  align-self: stretch;
  background-color: $white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px;
  position: relative;
  width: 100%;
  height: auto;
}

.main .overview {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.mainimage {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 20px 0px;
}

.mainrectangle {
  background-blend-mode: luminosity;
  position: relative;
  height: 124px;
  width: 124px;

  .rectangle {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
}

.maininfo {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.maindiv {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.maintextwrapper {
  color: $themeMain;
  font-family: 'SF UI Display-Bold', Helvetica;
  font-size: $fs-24;
  font-weight: $fw-bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mainresponsiblefor {
  align-self: stretch;
  color: $blue;
  font-family: $SfPro-regular;
  font-weight: $fw-medium;

  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  text-align: center;
}

.maintextwrapper2 {
  color: $themeMain;
  font-family: $SfPro-semibold;
  font-size: $fs-16;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.mainlist {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  // gap: 10px;
  justify-content: center;
  position: relative;
}

.maindiv2 {
  align-items: center;
  background-color: $white;
  border: 1px dashed;
  border-color: $dark-gray;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
  position: relative;
  width: 100px;
}

.maintextwrapper3 {
  color: $themeMain;
  font-family: $SfPro-regular;
  font-size: $fs-20;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  opacity: 0.8;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.maintextwrapper4 {
  color: $blue;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

// Define mixins
@mixin mid-text($size, $weight, $height) {
  font-family: $SfPro-regular;
  font-size: $size;
  font-weight: $weight;
  letter-spacing: 0;
  line-height: $height;
}

.MainBox {
  gap: 30px;
  display: flex;
  flex-direction: column;
}

// Use variables and mixins
.ContentHeadActionText {
  color: $darker-gray;
  @include mid-text(16px, 400, 12px);
  @include relative-position;
  @include fit-content;
}

.SubGrid {
  @include flex-row;
  @include flex-gap(10px);
  @include stretch-width;
  align-items: center;
}
.ContentGrid {
  @include flex-row;
  @include flex-gap(10px);
  // @include stretch-width;
  align-items: center;
  // background-color: #f1f1f4;
  border: 1px solid #f1f1f4;
  border-radius: 10px;
  padding: 5px;
  flex: 1 1 24%;
}

.MainGrid {
  background-color: $white;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #f1f1f4;
}

.ContentBox {
  @include flex-start;
  @include flex-column;
  @include flex-grow;
  @include flex-gap(10px);
  @include relative-position;
  background-color: $white;
  padding: 10px 20px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #f1f1f4;
}

.Content {
  @include flex-start;
  @include flex-column;
  @include flex-grow;
  @include flex-gap(10px);
  @include relative-position;
  background-color: $white;
  padding: 10px 20px;
  border-radius: 10px;
  width: 100%;
}

.ContentHeadBox {
  @include flex-center;
  @include flex-row;
  @include stretch-self;
  justify-content: space-between;
  @include relative-position;
  padding: 0px 10px;
}

.ContentHeadNameText {
  color: $black;
  font-family: $SfPro-bold;
  font-size: $fs-16;
  font-weight: $fw-bold;
  letter-spacing: 0;
  line-height: 30px;
}

.div1 {
  font-size: $fs-28;
  font-family: $SfPro-semibold;
  font-weight: 600;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.div2 {
  font-size: $fs-16;
  font-family: $SfPro-semibold;
  font-weight: 600;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0px;
}

.div6 {
  font-size: $fs-18;
  font-weight: $fw-light;
  font-family: $SfPro-regular;
  color: #99a1b7;
  text-wrap: pretty;
  padding: 10px 0px;
}

.ContentHeadActionBox {
  @include flex-center;
  @include flex-row;
  background-color: $gray;
  border-radius: 8px;
  @include flex-auto;
  @include flex-gap(10px);
  padding: 8px 15px;
  @include relative-position;
}

.ContentHeadSubText {
  @include stretch-self;
  color: $darker-gray;
  font-family: $SfPro-regular;
  font-size: $fs-18;
  font-weight: $fw-regular;
  letter-spacing: 0;
  line-height: 24px;
  @include relative-position;
}

.ContentTypeBox {
  @include flex-center;
  @include flex-row;
  background-color: $white;
  border: 1px solid;
  border-color: $dark-gray;
  border-radius: 100px;
  @include flex-auto;
  @include flex-gap(12px);
  justify-content: center;
  padding: 2px 15px;
  @include relative-position;
}

.ContentContactBox {
  @include flex-start;
  @include flex-row;
  @include stretch-self;
  @include flex-gap(30px);
  @include relative-position;
  @include stretch-width;
}

.ContentText {
  color: $darker-gray;
  @include mid-text(16px, 400, 12px);
  @include relative-position;
  @include fit-content;
  margin-top: -1px;
  white-space: nowrap;
}

.ContentContactSubBox {
  @include flex-center;
  @include flex-row;
  @include flex-auto;
  @include flex-gap(6px);
  @include relative-position;
}

.ellipse {
  background-color: $blue;
  border-radius: 2px;
  height: 4px;
  position: relative;
  width: 4px;
  margin: 5px;
}

.Avatar {
  height: 40px;
  width: 40px;
  filter: grayscale(100%);
}

.AvatarGrid {
  @include flex-center;
  align-items: center;
}

.Text2 {
  font-weight: $fw-medium;
  font-family: $SfPro-medium;
  color: $darker-gray;
  font-weight: $fw-regular;
}

.ContentHead {
  color: #78829d;
  font-family: $SfPro-medium;
  font-size: $fs-16;
  font-weight: $fw-medium;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-wrap: balance;
  font-size: $fs-16;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: #f1f1f4;
  white-space: nowrap;
}

.permissionBox {
  display: flex;
  padding: 10px 0px;
  align-items: flex-start;
}

.AddMemberButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $fs-20;
  font-family: $SfPro-semibold;
  margin-bottom: 30px;
}

.Add {
  align-items: center;
  align-self: stretch;
  background-color: $themeMain;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  padding: 8px 20px;
  margin: 0;
  height: auto;
}

.HeadBox {
  padding: 10px;
  border-radius: 10px;
  background-color: $dark-gray;
}

.ContentLabel {
  font-family: $SfPro-semibold;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: $black;
  padding: 10px 0px;
}
