@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  // gap: 10px;
  position: relative;
  width: 100%;
  // margin: 30px 0px;
}

.label {
  margin: 10px 0px;
  color: $black;
  // background-color: $white-variant-2;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-bold;
  text-wrap: pretty;
}

.div-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.textWrapper {
  position: relative;
  width: fit-content;
  text-wrap: pretty;
  font-family: $SfPro-semibold;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: $black;
}

.element {
  align-self: stretch;
  flex-direction: row;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
}

.radioSelectionBoxes {
  margin: 0;
  flex: 1;
  display: flex;
  flex-grow: 1;
  width: 100vh;
  padding: 20px;
  border-radius: 20px;
  border: 1px dashed blue;
  .radioText {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: unset;
    // padding: 20px;
  }
}

.radioSelectionBoxSelect {
  margin: 0;
  flex: 1;
  display: flex;
  flex-grow: 1;
  width: 100vh;
  padding: 20px;
  border-radius: 20px;
  border: 1px dashed blue;
  background-color: #f1eeff;
  .radioText {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: unset;
    // padding: 20px;
  }
}

.TxtQ {
  font-family: $SfPro-regular;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: $black;
}

.TxtA {
  //styleName: Text Regular/Regular;
  font-family: $SfPro-regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #99a1b7;
}


.label {
  margin: 10px 0px;
  color: $black;
  // background-color: $white-variant-2;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-bold;
}


.smallRadioSelectionBoxSelect {
  margin: 0;
  flex: 1;
  display: flex;
  flex-grow: 1;
  width: 120px;
  padding: 5px 20px;
  border-radius: 10px;
  border: 1px dashed blue;
  background-color: #f1eeff;
  .radioText {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: unset;
  }
}

.smallRadioSelectionBoxes {
  margin: 0;
  flex: 1;
  display: flex;
  flex-grow: 1;
  width: 120px;
  padding: 5px 20px;
  border-radius: 10px;
  border: 1px dashed blue;
  .radioText {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: unset;
  }
}
