@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.right {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 30px;
  position: relative;
}

.list {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.textWrapper {
  align-self: stretch;
  color: themeMain;
  font-family: $SfUi-semibold;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.alert {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ecf6ff;
  border: 1px dashed;
  border-color: #c8c9cc;
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px 15px;
  position: relative;
  width: 100%;
}

.frame {
  height: 28px;
  position: relative;
  width: 20px;
  z-index: 1;
}

.vuesaxTwotoneMagic {
  height: 16px !important;
  left: 2px !important;
  position: absolute !important;
  top: 6px !important;
  width: 16px !important;
}

.readyToSubmitYour {
  color: themeMain;
  flex: 1;
  font-family: $SfPro-regular;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  z-index: 0;
}

.main {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  position: relative;
  width: 100%;
}

.action {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: flex-end;
  padding: 30px 0px;
  position: relative;
  width: 100%;
}

.button {
  all: unset;
  align-items: center;
  background-color: #c8c9cc;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  opacity: 0;
  padding: 13px 15px;
  position: relative;
  width: 160px;
}

.div {
  color: themeMain;
  font-family: 'SF Pro Display-Medium', Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  opacity: 0.7;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.divWrapper {
  all: unset;
  align-items: center;
  background-color: $themeMain;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 13px 25px;
  position: relative;
  cursor: pointer;
}

.textWrapper2 {
  color: #ffffff;
  font-family: 'SF Pro Display-Medium', Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.textWrapper3 {
  color: $black;
  font-family: 'SF Pro Display-Medium', Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button2 {
  all: unset;
  align-items: center;
  background-color: #b2b2b7;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 12px 25px;
  position: relative;
  cursor: pointer;
}

.hiconLinear {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}
