@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.label {
  margin: 10px 0px;
  color: $black;
  // background-color: $white-variant-2;
  font-family: $SfPro-regular;
  font-size: $fs-16;
  font-weight: $fw-bold;
  text-wrap: pretty;
}
.checkBoxLabel {
  color: $black;
  // background-color: $white-variant-2;
  font-family: $SfPro-semibold;
  font-size: $fs-18;
  line-height: 24px;
  font-weight: 600;
  text-wrap: wrap;
  gap: 4px;
}
.AnsTxt {
  input {
    color: #8690ab;
    background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
  textarea {
    color: #8690ab;
    background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
  div:has(fieldset) {
    background-color: $white-variant-2 !important;
  }
  div[role='combobox'] {
    color: #8690ab;
    background-color: $white-variant-2;
    font-family: $SfPro-regular;
    font-size: $fs-16;
    font-weight: $fw-medium;
  }
}

.chevron {
  rotate: 180deg;
}

.Select {
  display: flex;
  width: 100%;
  height: 56px;
  //  border: 1px solid #ccc;
  background-color: $white-variant-2;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}

.star {
  margin: 8px;
}

.Heading {
  font-family: $SfPro-regular;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  text-align: left;
  color: $black;
}

.Check {
  padding: 0;
  &:has(input:checked) {
    color: $themeMain;
  }
}

.cardAddInstance {
  margin-top: 10px;
  margin-top: 16px;
  align-self: stretch;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: #f1eeff;
  border-radius: 20px;
}

.addNamesButton {
  padding: 5px 30px;
  background-color: #7752fe;
  margin: 0;
  text-wrap: nowrap;
}

.addNamesHeading {
  font-family: $SfPro-medium;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: $black;
}

.addNamesText {
  font-family: $SfPro-medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #78829d;
  padding: 6px 0px;
}

.radioBoxWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  overflow: auto;
  max-width: 58vw;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
}

.radioBox {
  display: flex;
  align-items: center;
  border: 1px dashed $themeMain;
  border-radius: 10px;
  // width: 120px;
  padding: 10px 25px;
  gap: 10px;
  background-color: #f1eeff;
  cursor: pointer;
}

.radioBoxActive {
  display: flex;
  align-items: center;
  border: 1px dashed $themeMain;
  border-radius: 10px;
  // width: 120px;
  padding: 10px 25px;
  gap: 10px;
  cursor: pointer;
}
