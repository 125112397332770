@import 'src/Styles/Variable/index.scss';
@import 'src/Styles/Mixin/index.scss';

.fontBold {
    font-weight: 700;
}
.fontColorBlack {
    color:themeMain;
}
.nameTitle {
      align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    color:#190482;
}
.StyledLabel{
    font-size: 19px;
}
.ssntext{
  color:#78829D;
}
.sticky {
   position: '-webkit-sticky';
   position: sticky;
   top: 0;
}
.Box1{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.profileBox{
  border-color: #F1F1F4;
  background-color: #F9F9F9;
}
.Box2{
  display: flex;
 justify-content: 'space-between';
  gap: 14px;
}
.semiBoldText {
  color: $black;
  text-align: center;
  flex-grow: 1;
  font-size: $fs-16;
  font-weight: $fw-bold;
}
.detailText{
  font-size: $fs-16;
  color: $black;
  font-weight: $fw-medium;

}

.OtherText {
  color: #6f737a;
   font-size: 14px;
  line-height: 143%;
}

.AvatarBox {
   display: flex;
   justify-content: 'space-between';
    gap: 10px;
    text-align: center;
    align-items: center;

}

